import React, { useState, useEffect, useMemo, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import UserContext from './context/UserContext';

export default function ITAdminUserTableSave({ userList, roleList, deleteList, editList, clearChangeLists, setUserList }) {
    const { user } = useContext(UserContext);
    
    const [statusList, setStatusList] = useState([]);
    const [statusPending, setStatusPending] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleConfirm = () => {
        setStatusPending(true);
        const defaultStatuses = Array(editList.length + deleteList.length).fill("PENDING");
        setStatusList(defaultStatuses);

        const editRequests = editList.map((edit, index) => {
            const payload = {
                user_id: edit.user_id,
                new_role_id: edit.role_id,
                user_email: user.email,
                user_hash: user.microsoft_id
            };
            return axios.post(
                'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_user_role',
                payload,
                { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
            )
            .then(response => {
                let status = "FAILURE"
                //console.log("Request for user "+payload.user_id+": ", response);
                if (response.data.body === '{"message":"User role updated successfully for user: '+ userList.find((aUser) => aUser.user_id === edit.user_id).user_name +'"}') status = "SUCCESS";
                return { 
                    response: response, 
                    index: index,
                    status: status
                }
            })
            .catch((e) => {
                console.log("Error: ", e);
                return {
                    response: "ERROR",
                    index: index,
                    status: "ERROR"
                }
            })
        });

        const deleteRequests = deleteList.map((user_id, index) => {
            const payload = {
                user_id: user_id,
                user_email: user.email,
                user_hash: user.microsoft_id
            };
            return axios.post(
                'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_delete_user',
                payload,
                { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY} }
            )
            .then(response => {
                let status = "FAILURE"
                //console.log("Request for user "+payload.user_id+": ", response);
                if (response.data.body === '{"message":"User deleted successfully"}') status = "SUCCESS";
                return { 
                    response: response, 
                    index: index + editRequests.length,
                    status: status
                }
            })
            .catch((e) => {
                console.log("Error: ", e);
                return {
                    response: "ERROR",
                    index: index + editRequests.length,
                    status: "ERROR"
                }
            })
        });
        
        Promise.all([...editRequests, ...deleteRequests])
        .then(responses => responses.forEach((response) => {
            //console.log(response.response);
            const statuses = statusList;
            statuses[response.index] = response.status
            setStatusList(statuses);
            setStatusPending(false);
        }));
    
    }

    const handleAccept = () => {
        setStatusList([]);
        clearChangeLists();
        setUserList(null);
        handleClose();
    }

    const getStatusIcon = (index) => {
        if (statusList.length === 0) return (
                <a data-bs-toggle="tooltip" title="Not started">
                    <i className="fa-solid fa-minus"></i>
                </a>
            );
        if (statusList[index] === 'PENDING') return (
            <a data-bs-toggle="tooltip" title="Pending">
                    <i className="fa-solid fa-hourglass"></i>
                </a>
        )
        if (statusList[index] === 'SUCCESS') return (
            <a data-bs-toggle="tooltip" title="Success">
                    <i className="fa-solid fa-check"></i>
                </a>
        )
        if (statusList[index] === 'FAILURE') return (
            <a data-bs-toggle="tooltip" title="Failure">
                    <i className="fa-solid fa-x"></i>
                </a>
        )
        if (statusList[index] === 'ERROR') return (
            <a data-bs-toggle="tooltip" title="Error">
                    <i className="fa-solid fa-circle-exclamation"></i>
                </a>
        )
    }

    return (
        <>
            <button 
                type="button" 
                className="btn btn-primary" 
                data-bs-toggle="modal" 
                data-bs-target="#staticBackdrop"
                disabled={deleteList.length === 0 && editList.length === 0}
                onClick={handleShow}
            >
                Save
            </button>

            <Modal 
                show={show} 
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Changing the following items:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th columnkey="user_id">User ID</th>
                                    <th columnkey="user_name">User Name</th>
                                    <th columnkey="changes">Changes</th>
                                    <th columnkey="status">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editList.length > 0 && editList.map((edit, index) => (
                                    <tr key={index}>
                                        <td>{edit.user_id}</td>
                                        <td>{userList.find(user => user.user_id === edit.user_id).user_name}</td>
                                        <td className="table-warning">{userList.find(user => user.user_id === edit.user_id).role_name} → <strong>{roleList.find(role => role.role_id === edit.role_id).role_name}</strong></td>
                                        <td className='text-center'>{getStatusIcon(index)}</td>
                                    </tr>
                                ))}
                                {deleteList.length > 0 && deleteList.map((user_id, index) => (
                                    <tr key={index + editList.length}>
                                        <td>{user_id}</td>
                                        <td>{userList.find(aUser => aUser.user_id === user_id).user_name}</td>
                                        <td className="table-danger"><strong>DELETE</strong></td>
                                        <td className='text-center'>{getStatusIcon(index + editList.length)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        (!statusPending && statusList.length === 0) &&
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={handleConfirm}>
                                Confirm
                            </Button>
                        </>
                    }
                    {
                        (statusList.length > 0) && 
                        <>
                            <Button variant="primary" onClick={handleAccept} disabled={statusPending}>
                                Accept
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}