import React, { useContext, useState, useEffect } from 'react';
import UserDetails from './UserDetails';
import News from './News';
import PhoneList from './PhoneList';
import TrainingTable from './TrainingTable';
import Information from './Information';
import LongContentExample from './LongContentExample';
import ITAdminDashboardContainer from './ITAdminDashboardContainer';
import ComponentSampler from './ComponentSampler';
import Loading from './Loading';
import UserContext from './context/UserContext';
import LeftSideMenu from './LeftSideMenu';
import Weather from './Weather';
import MobileFooter from './MobileFooter';
import { AppRoleEmulationContext } from '../App';
import './css/Home.css';

export default function Home() {
    const { user } = useContext(UserContext);
    const roleEmulation = useContext(AppRoleEmulationContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [menuExpanded, setMenuExpanded] = useState(false);
    
    // Function to handle window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Listen for menu state changes from localStorage
    useEffect(() => {
        const checkMenuState = () => {
            const menuState = localStorage.getItem('leftMenuExpanded');
            setMenuExpanded(menuState === 'true');
        };
        
        // Check on mount
        checkMenuState();
        
        // Set up a storage event listener
        const handleStorageChange = (e) => {
            if (e.key === 'leftMenuExpanded') {
                setMenuExpanded(e.newValue === 'true');
            }
        };
        
        window.addEventListener('storage', handleStorageChange);
        
        // Also check periodically (as a fallback)
        const interval = setInterval(checkMenuState, 1000);
        
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearInterval(interval);
        };
    }, []);

    // Get the effective role from the app context - handle 0 role correctly
    const effectiveRole = roleEmulation?.effectiveRole !== undefined ? 
                          roleEmulation.effectiveRole : 
                          user?.role_id;
    
    // Function to handle role emulation changes
    const handleRoleChange = (e) => {
        const newRole = parseInt(e.target.value, 10);
        
        // Special handling for role 0 - check with strict equality
        const roleToSet = newRole === user.role_id ? user.role_id : newRole;
        
        // Update the app context
        if (roleEmulation?.setEffectiveRole) {
            roleEmulation.setEffectiveRole(roleToSet);
            console.log("Setting new role to:", roleToSet);
        }
        
        // Also update localStorage directly as a backup
        localStorage.setItem('effectiveRole', roleToSet.toString());
    };

    // On initial load, sync with localStorage
    useEffect(() => {
        const storedRole = localStorage.getItem('effectiveRole');
        if (storedRole) {
            const parsedRole = parseInt(storedRole, 10);
            // Only update if different from current effectiveRole
            if (parsedRole !== effectiveRole && roleEmulation?.setEffectiveRole) {
                roleEmulation.setEffectiveRole(parsedRole);
            }
        }
    }, []);

    if (user === null) {
        return (
            <Loading componentName={"App"} />
        )
    } 
    /*else if (user.role_id === 0) {
        return (
            <div>
                <h1>Welcome!</h1>
                <p>You are a valid user with no permissions. Please contact an administrator to obtain permissions.</p>
            </div>
        )
    }*/
    else if (user.role_id >= 0) {
        // Log the entire user object to debug what properties are available
        console.log("Full user object:", user);
        
        // Try different potential name properties based on AppointmentScreen.jsx
        const userName = user.name || user.display_name || user.displayName || 
                         user.firstName || user.first_name || 
                         user.username || user.email || 'User';
        
        // Is this an emulated role?
        const isEmulated = effectiveRole !== user.role_id;
        
        return (
            <div className="home-page-layout">
                {/* Pass effectiveRole to LeftSideMenu */}
                {!isMobile ? <LeftSideMenu effectiveRole={effectiveRole} /> : null}
                
                <div className={`home-container ${isMobile ? 'mobile-container' : ''} ${menuExpanded && !isMobile ? 'menu-expanded' : ''}`}>
                    {/* Welcome content first */}
                    <div className="welcome-content">
                        <h1>Welcome, {userName}!</h1>
                        <p>Select an option from the menu to get started.</p>
                        
                        {/* Simplified Role emulation control - only for admins (role 9) */}
                        {user.role_id === 9 && (
                            <div className="role-emulation-control" style={{
                                padding: '10px',
                                marginBottom: '20px',
                                borderRadius: '5px',
                                backgroundColor: isEmulated ? '#f8f9fa' : 'transparent',
                                border: isEmulated ? '1px solid #dee2e6' : 'none'
                            }}>
                                <div className="d-flex align-items-center">
                                    <label htmlFor="role-select" className="me-2 mb-0" style={{
                                        fontSize: '0.9rem', 
                                        fontWeight: 'bold'
                                    }}>
                                        {isEmulated ? 
                                            '🔄 Emulating:' : 
                                            '👁️ View as:'}
                                    </label>
                                    <select 
                                        id="role-select"
                                        className="form-select form-select-sm" 
                                        value={effectiveRole}
                                        onChange={handleRoleChange}
                                        style={{
                                            maxWidth: '200px',
                                            display: 'inline-block'
                                        }}
                                    >
                                        <option value={user.role_id}>Actual Role ({user.role_id})</option>
                                        <option disabled>─────────────</option>
                                        <option value={0}>Role 0 - N00b</option>
                                        <option value={1}>Role 1 - Technician</option>
                                        <option value={2}>Role 2 - Dispatcher</option>
                                        <option value={8}>Role 8 - Operations Admin</option>
                                        <option value={9}>Role 9 - Administrator</option>
                                    </select>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    {/* Weather component for mobile only - now after welcome message */}
                    {isMobile && <Weather />}
                    
                    <img 
                        src="/images/collins-logo-2020-rgb 2_RGB_bug.png" 
                        alt="Collins Logo" 
                        className="background-logo"
                    />
                </div>

                {/* For mobile view, pass effectiveRole to MobileFooter with a key to force re-render */}
                {isMobile && <MobileFooter 
                    key={`mobile-footer-${effectiveRole}`} 
                    effectiveRole={effectiveRole} 
                />}
            </div>
        )
    }
    else {
        return (
            <div>
                <h1>Something broke!</h1>
                <p>Try relogging. If that doesn't work, contact an administrator.</p>
            </div>
        )
    }
}