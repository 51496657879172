// src/utils/fetchVendors.js

import axios from 'axios';

const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Shared API Key for endpoints

/**
 * Fetches vendors from the API.
 * @returns {Promise<Array>} An array of vendor objects.
 */
export const fetchVendors = async () => {
  const GET_VENDORS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_vendors';

  try {
    console.log('Calling API to fetch vendors...');
    const response = await axios.get(GET_VENDORS_ENDPOINT, {
      headers: {
        'x-api-key': API_KEY,
      },
    });

    //console.log('API Response:', response);

    if (response.status !== 200) {
      throw new Error(`Failed to fetch vendors: ${response.statusText}`);
    }

    const data = response.data;
    //console.log('Response data:', data);

    // Check if the data has the expected structure
    if (!data || !Array.isArray(data.vendors)) {
      throw new Error('Invalid response format: "vendors" array is missing.');
    }

    const vendorsData = data.vendors;
    //console.log('Vendors data:', vendorsData);

    // Filter vendors where UsrStaffMember.value === true and map the required properties
    const filteredVendors = vendorsData
      .filter((vendor) => vendor.UsrStaffMember?.value === true)
      .map((vendor) => ({
        id: vendor.id || vendor.VendorID?.value,
        vendorName: vendor.VendorName?.value || 'N/A',
        vendorEmail: vendor.UsrVendorEmail?.value || 'N/A',
        vendorPhone: vendor.UsrVendorPhone?.value || 'N/A',
      }));

    //console.log('Filtered vendors:', filteredVendors);

    return filteredVendors;
  } catch (error) {
    console.error('Error fetching vendors:', error);
    throw error;
  }
};