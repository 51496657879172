import React, { useState, useContext } from 'react';
import UserContext from './context/UserContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { EncodeDecodeContext } from './context/EncodeDecodeContext';
import './css/components.css';
import Submitting from './Submitting';

export default function TrainingTableRowEdit ({ item, trainingCategories, mediaTypes, refreshTrainingList }) {
    const { user } = useContext(UserContext);
    const { encodeInput } = useContext(EncodeDecodeContext);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editName, setEditName] = useState(item.training_name);
    const [editUrl, setEditUrl] = useState(item.training_url);
    const [editCategory, setEditCategory] = useState(getTrainingCategoryId());
    const [editMediaType, setEditMediaType] = useState(getMediaTypeId());

    const [sendingRequest, setSendingRequest] = useState(false);

    function getTrainingCategoryId() {
        const foundId = trainingCategories.find((category) => category.training_category_name === item.training_category_name).training_category_id;
        return foundId;
    }

    function getMediaTypeId() {
        const foundId = mediaTypes.find((medium) => medium.media_type_name === item.media_type_name).media_type_id;
        return foundId;
    }

    const sendEditRequest = async () => {
        const payload = {
            training_id: item.training_id,
            training_name: encodeInput(editName),
            training_url: encodeInput(editUrl), 
            training_category_id: editCategory,
            media_type_id: editMediaType,
            user_name: user.email
        };
        //console.log("payload: ", payload)
        await axios.post(
            'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_training',
            payload,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        )//.then(response => console.log(response))
        .catch((e) => console.log(e))
    }

    const handleEditNameChange = (e) => setEditName(e.target.value);
    const handleEditUrlChange = (e) => setEditUrl(e.target.value);
    const handleEditCategoryChange = (e) => setEditCategory(e.target.value);
    const handleEditMediaTypeChange = (e) => setEditMediaType(e.target.value);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = () => setShowEditModal(true);
    const handleConfirmEditModal = async () => {
        if (editName === "" || editUrl === "") {
            alert("Please fill out all fields.")
        } else {
            setSendingRequest(true);
            await sendEditRequest();
            refreshTrainingList();
            handleCloseEditModal();
            setSendingRequest(false);
        }
    }

    return (
        <>
            <a >
                <i className="fa-solid fa-pen-to-square note__edit" aria-hidden="true" onClick={handleShowEditModal}></i>
            </a>
            <Modal 
                show={showEditModal} 
                onHide={handleCloseEditModal}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Please set desired values:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        sendingRequest ?
                        <Submitting displayText={"Submitting edit request"} /> :
                        <>
                            <label >Name:</label>
                            <input className='fill__width' value={editName} onChange={handleEditNameChange} />
                            
                            <label>URL:</label>
                            <input className='fill__width' value={editUrl} onChange={handleEditUrlChange} />
                        
                            <label>Training Category:</label>
                            <select className='fill__width' selected={editCategory} onChange={handleEditCategoryChange}>
                                {
                                    trainingCategories.map((category, index) => (
                                        <option 
                                            key={index} 
                                            value={category.training_category_id}
                                            selected={category.training_category_id === editCategory}
                                        >{category.training_category_name}</option>))
                                }
                            </select>

                            <label>Media Type:</label>
                            <select className='fill__width' selected={editMediaType} onChange={handleEditMediaTypeChange}>
                                {
                                    mediaTypes.map((medium, index) => (
                                        <option 
                                            key={index} 
                                            value={medium.media_type_id}
                                            selected={medium.media_type_id === editMediaType}
                                        >{medium.media_type_name}</option>))
                                }
                            </select>
                            <p>(Training ID: <strong>{item.training_id}</strong>)</p>
                            <p>This action cannot be undone.</p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditModal} disabled={sendingRequest}>
                        Cancel
                    </Button>
                    <Button variant="danger"onClick={handleConfirmEditModal} disabled={sendingRequest}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}