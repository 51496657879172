// ImageGallery.jsx
import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import { Modal, Button, Spinner } from 'react-bootstrap';
import imageCompression from 'browser-image-compression'; // For compressing before upload

// Configure AWS S3 using environment variables (make sure these are defined in your .env file)
const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const bucketName = process.env.REACT_APP_AWS_BUCKET;

/**
 * props:
 *  - custId (string) or CustID (string): Customer ID / folder (required).
 *  - serviceOrder (string)      : If given, only show that service order's appointments.
 *  - activeAppointment (string) : e.g. "FS005248-7"; if provided, restrict deletion to matching appointment folder.
 *  - allowAddImages (boolean)   : if true (default), display the "+" icon and enable image uploads; if false, hide the "+" icon and disallow image uploads.
 *  - refreshCounter (number)    : triggers image list refresh when updated.
 *  - allAppointments (array)   : array of all appointment numbers for the service order
 */
const ImageGallery = (props) => {
  const { serviceOrder, activeAppointment, allowAddImages, refreshCounter } = props;
  // Use effectiveCustId: first try custId, fallback to CustID.
  const effectiveCustId = props.custId || props.CustID;
  // New prop to get all appointment numbers, even those without images
  const allAppointments = props.allAppointments || [];

  const [galleryData, setGalleryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // For displaying a full-size image in a modal
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  // Trigger re-list of S3 objects whenever something changes (e.g., after deletion)
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const triggerRefresh = () => setRefreshTrigger((prev) => prev + 1);

  // Simple loading state for new uploads
  const [uploading, setUploading] = useState(false);

  const [isComponentMounted, setIsComponentMounted] = useState(true);

  const fetchImages = async () => {
    try {
      const response = await fetch(
        `/api/images?custId=${effectiveCustId}&serviceOrder=${serviceOrder}&appointment=${activeAppointment}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch images.');
      }
      const data = await response.json();
      setGalleryData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Failed to load images.');
      setLoading(false);
    }
  };

  useEffect(() => {
    // Set mounted flag
    setIsComponentMounted(true);
    
    if (!effectiveCustId) {
      setError('custId is required.');
      setLoading(false);
      return;
    }
    if (!serviceOrder) {
      setError('serviceOrder is required for listing appointments.');
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    // Build the prefix to search in S3 using effectiveCustId.
    let prefix = `${effectiveCustId}/`;
    if (serviceOrder) {
      prefix += `${serviceOrder}/`;
    }

    // Delimiter '/' helps group by folders
    const params = {
      Bucket: bucketName,
      Prefix: prefix,
      Delimiter: '/',
    };

    // Use let for these variables so we can clear them in cleanup
    let s3Request = null;
    
    const fetchData = () => {
      s3Request = s3.listObjectsV2(params, (err, data) => {
        // Return early if component unmounted
        if (!isComponentMounted) return;
        
        if (err) {
          console.error('Error listing S3 objects:', err);
          setError('Failed to load images from S3.');
          setLoading(false);
          return;
        }

        // Process the results data
        const result = { serviceOrders: [], appointments: [] };

        // For a specific service order, group appointments and their images
        if (serviceOrder) {
          // Extract appointment folders (common prefixes with delimiter '/')
          const appointmentFolders = data.CommonPrefixes || [];
          const appointmentNumbers = appointmentFolders.map((prefix) => {
            // Extract appointment number from prefix (custId/serviceOrder/appointment/)
            const folderPath = prefix.Prefix;
            const parts = folderPath.split('/');
            // Return the appointment number (third part, index 2)
            return parts[2];
          });

          // Create a record of appointment folders we found in S3
          const existingAppointments = new Set(appointmentNumbers);

          // Fetch images for each appointment - with early exit checks
          const appointmentPromises = appointmentFolders.map((prefix) => {
            return new Promise((resolve) => {
              // Return early if component unmounted
              if (!isComponentMounted) {
                resolve({ appointment: 'unmounted', images: [] });
                return;
              }
              
              // Extract appointment number
              const appointmentNumber = prefix.Prefix.split('/')[2];

              // List objects in this appointment folder
              s3.listObjectsV2(
                {
                  Bucket: bucketName,
                  Prefix: prefix.Prefix,
                },
                (err, appData) => {
                  // Return early if component unmounted
                  if (!isComponentMounted) {
                    resolve({ appointment: 'unmounted', images: [] });
                    return;
                  }
                  
                  if (err) {
                    console.error(`Error listing objects for appointment ${appointmentNumber}:`, err);
                    resolve({ appointment: appointmentNumber, images: [] });
                    return;
                  }

                  // Filter out any "folders" and return only image objects
                  const images = (appData.Contents || []).filter(
                    (obj) => !obj.Key.endsWith('/')
                  );

                  resolve({ appointment: appointmentNumber, images });
                }
              );
            });
          });

          // Wait for all appointment image fetches to complete
          Promise.all(appointmentPromises)
            .then((appointmentsWithImages) => {
              // Return early if component unmounted
              if (!isComponentMounted) return;
              
              // Filter out any 'unmounted' responses (from early exits)
              appointmentsWithImages = appointmentsWithImages.filter(
                app => app.appointment !== 'unmounted'
              );
              
              result.appointments = appointmentsWithImages;
              
              // Only process allAppointments if they are provided AND we have a serviceOrder context
              if (serviceOrder && Array.isArray(allAppointments) && allAppointments.length > 0) {
                // Create a set for faster lookups
                const existingAppointments = new Set(
                  appointmentsWithImages.map(app => app.appointment.toString())
                );
                
                allAppointments.forEach(apptNumber => {
                  if (apptNumber && !existingAppointments.has(apptNumber.toString())) {
                    // Add a placeholder entry with an empty images array
                    result.appointments.push({
                      appointment: apptNumber.toString(),
                      images: []
                    });
                  }
                });
                
                // Sort appointments numerically for consistent display
                result.appointments.sort((a, b) => {
                  return parseInt(a.appointment, 10) - parseInt(b.appointment, 10);
                });
              }
              
              // Set state only if component is still mounted
              if (isComponentMounted) {
                setGalleryData(result);
                setLoading(false);
              }
            })
            .catch((err) => {
              // Return early if component unmounted
              if (!isComponentMounted) return;
              
              console.error('Error processing appointment images:', err);
              setError('Failed to process images.');
              setLoading(false);
            });
        } else {
          // More general case (no service order specified)
          // ... [original code for service order listing]
          // Just ensure we check isComponentMounted here too
          setGalleryData(result);
          setLoading(false);
        }
      });
    };
    
    // Start the data fetching
    fetchData();

    // Cleanup function to handle unmounting
    return () => {
      setIsComponentMounted(false);
      // Abort any pending S3 requests if possible
      if (s3Request && typeof s3Request.abort === 'function') {
        s3Request.abort();
      }
    };
  }, [effectiveCustId, serviceOrder, activeAppointment, refreshCounter, refreshTrigger]);

  // Make a separate effect for handling allAppointments changes to avoid unnecessary refreshes
  useEffect(() => {
    // Only update if we already have data and aren't currently loading
    if (!loading && galleryData.appointments && Array.isArray(allAppointments) && allAppointments.length > 0) {
      const existingAppointments = new Set(
        galleryData.appointments.map(app => app.appointment.toString())
      );
      
      let needsUpdate = false;
      
      // Check if we need to add any new appointments
      allAppointments.forEach(apptNumber => {
        if (apptNumber && !existingAppointments.has(apptNumber.toString())) {
          needsUpdate = true;
        }
      });
      
      // If we need to update, do it without triggering a full reload
      if (needsUpdate) {
        const updatedAppointments = [...galleryData.appointments];
        
        allAppointments.forEach(apptNumber => {
          if (apptNumber && !existingAppointments.has(apptNumber.toString())) {
            updatedAppointments.push({
              appointment: apptNumber.toString(),
              images: []
            });
          }
        });
        
        // Sort appointments numerically for consistent display
        updatedAppointments.sort((a, b) => {
          return parseInt(a.appointment, 10) - parseInt(b.appointment, 10);
        });
        
        setGalleryData(prev => ({
          ...prev,
          appointments: updatedAppointments
        }));
      }
    }
  }, [allAppointments, galleryData.appointments, loading]);

  // Show the image in a modal
  const handleThumbnailClick = (key) => {
    const url = `https://${bucketName}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
    setModalImage(url);
    setShowModal(true);
  };

  // Delete an image from S3
  const handleDeleteImage = async (key, appointmentFolder) => {
    // If activeAppointment is set, only allow deletion if we match that folder
    if (activeAppointment) {
      if (appointmentFolder !== activeAppointment) {
        alert('You do not have permission to delete from this appointment.');
        return;
      }
    }
    // Confirm deletion
    const approve = window.confirm('Are you sure you want to delete this image?');
    if (!approve) return;

    try {
      await s3.deleteObject({ Bucket: bucketName, Key: key }).promise();
      triggerRefresh(); // refresh local gallery list
      // Notify the parent so it can re-run the image check
      if (typeof props.onGalleryRefresh === 'function') {
        props.onGalleryRefresh();
      }
    } catch (delErr) {
      console.error('Error deleting image:', delErr);
      alert('Error deleting image. Check console for details.');
    }
  };

  // Uploading New Images to a Specific Appointment
  const handleAddImagesClick = (appointmentLabel) => {
    if (!allowAddImages) return;

    // Create a hidden file input on the fly
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = true;

    input.onchange = async (e) => {
      const files = Array.from(e.target.files);
      if (files.length === 0) return;

      // Compress and upload each image
      await uploadImagesToAppointment(appointmentLabel, files);
    };

    input.click();
  };

  const uploadImagesToAppointment = async (appointmentLabel, files) => {
    setUploading(true);
    try {
      const compressionOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      for (const file of files) {
        let compressedFile = file;
        try {
          compressedFile = await imageCompression(file, compressionOptions);
        } catch (compressErr) {
          console.warn('Compression failed; using original file.', compressErr);
        }

        // Build the S3 key:  custId/serviceOrder/appointmentNumber/timestamp-fileName
        const timestamp = Date.now();
        const safeFileName = compressedFile.name.replace(/\s+/g, '_');
        const key = `${effectiveCustId}/${serviceOrder}/${appointmentLabel}/${timestamp}-${safeFileName}`;

        const uploadParams = {
          Bucket: bucketName,
          Key: key,
          Body: compressedFile,
          ContentType: compressedFile.type,
        };

        await s3.upload(uploadParams).promise();
      }
      // Refresh to see newly uploaded images
      triggerRefresh();
      // Also inform the parent about the gallery update
      if (typeof props.onGalleryRefresh === 'function') {
        props.onGalleryRefresh();
      }
    } catch (err) {
      console.error('Error uploading images to S3:', err);
      alert('Error uploading images. Check console for details.');
    } finally {
      setUploading(false);
    }
  };

  // Renders all thumbnails for a given array of images (S3 objects)
  // and tags them with the associated appointment folder.
  const renderThumbnails = (images, appointmentLabel) => {
    // Add console logs to help diagnose
    console.log("Rendering thumbnails for appointment:", appointmentLabel);
    console.log("Active appointment:", activeAppointment);
    console.log("Types:", typeof appointmentLabel, typeof activeAppointment);
    
    // Ensure proper string comparison by converting both to strings
    const appointmentLabelStr = String(appointmentLabel);
    const activeAppointmentStr = activeAppointment ? String(activeAppointment) : null;
    
    // Updated condition for delete permissions
    const canDelete = !activeAppointmentStr || appointmentLabelStr === activeAppointmentStr;
    
    console.log("Can delete:", canDelete);

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
        {images.map((image) => {
          const url = `https://${bucketName}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${image.Key}`;
          return (
            <div key={image.Key} style={{ position: 'relative' }}>
              <img
                src={url}
                alt="Thumbnail"
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'cover',
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                }}
                onClick={() => handleThumbnailClick(image.Key)}
              />
              {canDelete && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteImage(image.Key, appointmentLabel);
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'red',
                    color: '#fff',
                    border: 'none',
                    padding: '3px 5px',
                    cursor: 'pointer',
                    borderRadius: '0 0 0 4px',
                  }}
                  title="Delete this image"
                >
                  X
                </button>
              )}
            </div>
          );
        })}

        {/* Plus icon for adding new images to this appointment */}
        {/* Only show add button if allowAddImages is true AND
            (no activeAppointment is set OR this is the active appointment) */}
        {allowAddImages && (!activeAppointmentStr || appointmentLabelStr === activeAppointmentStr) && (
          <div
            onClick={() => handleAddImagesClick(appointmentLabel)}
            style={{
              fontSize: '30px',
              width: '40px',
              height: '40px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: '#28a745', // green
              backgroundColor: '#f8f9fa',
            }}
            title={`Add images to appointment #${appointmentLabel}`}
          >
            +
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Spinner animation="border" role="status" size="sm" /> Loading images...
      </div>
    );
  }

  if (error) {
    return <div style={{ padding: '20px', color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div style={{ padding: '20px' }}>
      {uploading && (
        <div style={{ marginBottom: '10px', color: '#555' }}>
          Uploading images...
        </div>
      )}

      {/* Add global image controls here if needed */}
      {serviceOrder && allowAddImages && (
        <div className="image-controls" style={{ marginBottom: '20px' }}>
          <Button 
            variant="success" 
            size="sm"
            onClick={() => {
              // If activeAppointment is set, use it, otherwise use the first appointment
              const targetAppointment = activeAppointment || 
                (galleryData.appointments && galleryData.appointments.length > 0 ? 
                  galleryData.appointments[0].appointment : null);
                  
              if (targetAppointment) {
                handleAddImagesClick(targetAppointment);
              } else {
                alert("No appointment available to add images to");
              }
            }}
            style={{ marginRight: '10px' }}
          >
            Add Photos
          </Button>
        </div>
      )}

      {serviceOrder ? (
        // When a service order is supplied, show each appointment group
        galleryData.appointments &&
        galleryData.appointments.map((app) => (
          <div key={app.appointment} style={{ marginBottom: '30px' }}>
            <h4>Appointment #{app.appointment}</h4>
            {renderThumbnails(app.images, app.appointment)}
          </div>
        ))
      ) : (
        // Otherwise show every serviceOrder, each with its own appointments
        galleryData.serviceOrders &&
        galleryData.serviceOrders.map((so) => (
          <div key={so.serviceOrder} style={{ marginBottom: '40px' }}>
            <h3>{so.serviceOrder}</h3>
            {so.appointments &&
              so.appointments.map((app) => (
                <div key={app.appointment} style={{ marginLeft: '20px', marginBottom: '20px' }}>
                  <h4>Appointment #{app.appointment}</h4>
                  {renderThumbnails(app.images, app.appointment)}
                </div>
              ))}
          </div>
        ))
      )}

      {/* Modal for full-size image preview */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Body>
          {modalImage && (
            <img
              src={modalImage}
              alt="Full Size"
              style={{ width: '100%', objectFit: 'contain' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImageGallery;
