import React, { useState, useContext } from 'react';
import InputMask from 'react-input-mask';
import { sendEmail } from '../utils/email';
import UserContext from './context/UserContext';
 
const NewEmployeeSetup = () => {
  const { user } = useContext(UserContext);
  const [name, setName] = useState('');
  const [cellPhone, setCellPhone] = useState('');
  const [adpId, setAdpId] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [position, setPosition] = useState('');
  const [notes, setNotes] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailError, setEmailError] = useState(null);
 
  // Ensure the form is valid (all fields are required)
  const isFormValid =
    name.trim() &&
    cellPhone.trim() &&
    adpId.trim() &&
    supervisor.trim() &&
    position.trim();
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    // If for some reason the fields are incomplete, do nothing.
    if (!isFormValid) {
      return;
    }
 
    // Reset alert messages and set sending state
    setEmailSending(true);
    setEmailSuccess(null);
    setEmailError(null);
 
    // Prepare the email subject and body including the CC adding the user email
    const emailSubject = "New Employee - Setup";
    const emailBody = `
New Employee Setup Request:
------------------------------
Name: ${name}
Cell Phone Number: ${cellPhone}
ADP ID: ${adpId}
Supervisor: ${supervisor}
Position: ${position}
Notes: ${notes}
 
Submitted by: ${user.display_name || user.name || user.email}
    `;
    
    // Add joanna.hooper@collins-cs.com to CC if she is not the one submitting the request
    const ccValue =
      user.email.toLowerCase() === 'joanna.hooper@collins-cs.com'
        ? user.email
        : `${user.email}, joanna.hooper@collins-cs.com`;
 
    try {
      await sendEmail({
        toAddress: 'ithelp@collins-cs.com',
        cc: ccValue,
        replyTo: user.email,
        subject: emailSubject,
        body: emailBody,
      });
      setEmailSuccess('Your request has been sent successfully.');
      // Optionally, reset the form fields after submission
      setName('');
      setCellPhone('');
      setAdpId('');
      setSupervisor('');
      setPosition('');
      setNotes('');
    } catch (error) {
      setEmailError(error.message || 'Error sending request. Please try again.');
    } finally {
      setEmailSending(false);
    }
  };
 
  return (
    <div className="container" style={{ maxWidth: '600px', marginTop: '20px' }}>
      <h1 className="mb-4">New Employee Setup Request</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="name"><strong>Name</strong></label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Name in ADP"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="cellPhone"><strong>Cell Phone Number</strong></label>
          <InputMask
            mask="999-999-9999"
            value={cellPhone}
            onChange={(e) => setCellPhone(e.target.value)}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="tel"
                className="form-control"
                id="cellPhone"
                placeholder="999-999-9999"
                required
              />
            )}
          </InputMask>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="adpId"><strong>ADP ID</strong></label>
          <input
            type="text"
            className="form-control"
            id="adpId"
            placeholder="Enter ADP ID"
            value={adpId}
            onChange={(e) => setAdpId(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="supervisor"><strong>Supervisor</strong></label>
          <input
            type="text"
            className="form-control"
            id="supervisor"
            placeholder="Enter Supervisor"
            value={supervisor}
            onChange={(e) => setSupervisor(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="position"><strong>Position</strong></label>
          <input
            type="text"
            className="form-control"
            id="position"
            placeholder="Enter position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="notes"><strong>Notes</strong></label>
          <textarea
            className="form-control"
            id="notes"
            rows="3"
            placeholder="Enter additional notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            //required
          />
        </div>
        {emailError && (
          <div className="alert alert-danger" role="alert">
            {emailError}
          </div>
        )}
        {emailSuccess && (
          <div className="alert alert-success" role="alert">
            {emailSuccess}
          </div>
        )}
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isFormValid || emailSending}
        >
          {emailSending ? 'Submitting...' : 'Submit Request'}
        </button>
      </form>
    </div>
  );
};
 
export default NewEmployeeSetup;
 