import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserContext from './context/UserContext';
import menuItems from '../utils/menuitems';
import './css/LeftSideMenu.css';

const LeftSideMenu = ({ effectiveRole }) => {
  const { user } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');
  // Force default to true regardless of any saved state
  const [isExpanded, setIsExpanded] = useState(true);
  
  // Load menu state from localStorage with more robust handling
  useEffect(() => {
    // Set a flag in sessionStorage to track first load
    const isFirstLoad = sessionStorage.getItem('leftMenuInitialized') !== 'true';
    
    if (isFirstLoad) {
      // On first load, always set to expanded and save that state
      localStorage.setItem('leftMenuExpanded', 'true');
      setIsExpanded(true);
      // Mark as initialized
      sessionStorage.setItem('leftMenuInitialized', 'true');
      console.log('Left menu initialized to expanded state');
    } else {
      // After first load, respect user preference
      const savedState = localStorage.getItem('leftMenuExpanded');
      // Default to true if value is null or anything other than 'false'
      const shouldExpand = savedState !== 'false';
      setIsExpanded(shouldExpand);
      console.log('Left menu loaded from saved state:', shouldExpand);
    }
    
    // Force expanded state after a short delay (helps with browser specific issues)
    const timer = setTimeout(() => {
      if (isFirstLoad || localStorage.getItem('leftMenuExpanded') !== 'false') {
        setIsExpanded(true);
        console.log('Left menu forced to expanded state after delay');
      }
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);
  
  // Early return if user is not loaded
  if (!user) return null;

  // Use effectiveRole if provided, otherwise fall back to user.role_id
  const roleForMenus = effectiveRole !== undefined ? effectiveRole : user.role_id;

  // Filter menu items based on search term and effective role
  const filteredItems = menuItems.filter(item => 
    item.role <= roleForMenus && 
    (searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Toggle menu expansion
  const toggleMenu = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    // Save preference to localStorage
    localStorage.setItem('leftMenuExpanded', newState.toString());
    console.log('Left menu toggled to:', newState);
  };

  // Highlight matching text in search results
  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    
    return (
      <span>
        {parts.map((part, i) => 
          regex.test(part) ? 
            <span key={i} className="search-highlight">{part}</span> : 
            <span key={i}>{part}</span>
        )}
      </span>
    );
  };

  return (
    <>
      {/* Toggle button that's always visible */}
      <button 
        className={`menu-toggle-button ${isExpanded ? 'expanded' : 'collapsed'}`}
        onClick={toggleMenu}
        aria-label={isExpanded ? 'Collapse menu' : 'Expand menu'}
      >
        <i className={`fas fa-chevron-${isExpanded ? 'left' : 'right'}`}></i>
      </button>

      <div className={`left-side-menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
        {/* Only show content when expanded */}
        {isExpanded && (
          <>
            {/* Home item is always shown */}
            <div className="menu-section">
              <div className="menu-item">
                <Link to="/" className="menu-link main-link">
                  <i className="fas fa-home"></i> Home
                </Link>
              </div>
            </div>

            {/* Search box */}
            <div className="search-container">
              <div className="search-box">
                <i className="fas fa-search search-icon"></i>
                <input
                  type="text"
                  placeholder="Search menu..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                {searchTerm && (
                  <button 
                    className="clear-search" 
                    onClick={() => setSearchTerm('')}
                    aria-label="Clear search"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>

            {/* If searching, show flat list of results */}
            {searchTerm ? (
              <div className="search-results">
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <div className="menu-item" key={index}>
                      {item.isExternal ? (
                        <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer">
                          <i className={item.icon}></i> {highlightText(item.name, searchTerm)}
                          {item.category && <span className="category-tag">{item.category}</span>}
                        </a>
                      ) : (
                        <Link to={item.path} className="menu-link">
                          <i className={item.icon}></i> {highlightText(item.name, searchTerm)}
                          {item.category && <span className="category-tag">{item.category}</span>}
                        </Link>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-results">No menu items found</div>
                )}
              </div>
            ) : (
              // If not searching, show categorized menu
              <>
                {/* Techroom section */}
                <div className="menu-section">
                  <div className="menu-header">
                    <i className="fas fa-tools"></i> Techroom
                  </div>
                  <div className="submenu">
                    {filteredItems
                      .filter(item => item.category === 'Techroom')
                      .map((item, index) => (
                        <div className="menu-item" key={index}>
                          {item.isExternal ? (
                            <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer">
                              <i className={item.icon}></i> {item.name}
                            </a>
                          ) : (
                            <Link to={item.path} className="menu-link">
                              <i className={item.icon}></i> {item.name}
                            </Link>
                          )}
                        </div>
                      ))}
                  </div>
                </div>

                {/* Operations section */}
                {roleForMenus >= 2 && (
                  <div className="menu-section">
                    <div className="menu-header">
                      <i className="fas fa-cogs"></i> Operations
                    </div>
                    <div className="submenu">
                      {filteredItems
                        .filter(item => item.category === 'Operations')
                        .map((item, index) => (
                          <div className="menu-item" key={index}>
                            {item.isExternal ? (
                              <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer">
                                <i className={item.icon}></i> {item.name}
                              </a>
                            ) : (
                              <Link to={item.path} className="menu-link">
                                <i className={item.icon}></i> {item.name}
                              </Link>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {/* IT Admin section */}
                {roleForMenus >= 9 && (
                  <div className="menu-section">
                    <div className="menu-header">
                      <i className="fas fa-user-shield"></i> IT Admin
                    </div>
                    <div className="submenu">
                      {filteredItems
                        .filter(item => item.category === 'IT Admin')
                        .map((item, index) => (
                          <div className="menu-item" key={index}>
                            {item.isExternal ? (
                              <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer">
                                <i className={item.icon}></i> {item.name}
                              </a>
                            ) : (
                              <Link to={item.path} className="menu-link">
                                <i className={item.icon}></i> {item.name}
                              </Link>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LeftSideMenu;
