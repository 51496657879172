import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Table, Button, Spinner, Alert } from 'react-bootstrap';
import './css/DeviceAudit.css';

const DeviceAudit = () => {
  const [deviceData, setDeviceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchDeviceData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_device_log',
          {}, // Empty payload
          { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        );
        setDeviceData(response.data.records);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching device log data:", err);
        setError("Failed to load device audit data. Please try again later.");
        setLoading(false);
      }
    };

    fetchDeviceData();
  }, []);

  // Initialize Bootstrap tooltips after data loads
  useEffect(() => {
    if (!loading && tableRef.current) {
      const tooltipTriggerList = [].slice.call(
        tableRef.current.querySelectorAll('[data-toggle="tooltip"]')
      );
      
      if (typeof window !== 'undefined' && window.$ && window.$.fn.tooltip) {
        window.$(tooltipTriggerList).tooltip();
      }
    }
  }, [loading, deviceData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Function to determine device icon based on user agent string
  const getDeviceIcon = (userAgent) => {
    const ua = userAgent.toLowerCase();
    
    if (ua.includes('iphone') || ua.includes('ipad') || ua.includes('ipod')) {
      return <i className="fab fa-apple text-dark" title={userAgent}></i>;
    } else if (ua.includes('mac os')) {
      return <i className="fas fa-laptop-apple text-dark" title={userAgent}></i>;
    } else if (ua.includes('android')) {
      return <i className="fab fa-android text-success" title={userAgent}></i>;
    } else if (ua.includes('windows phone')) {
      return <i className="fab fa-windows text-info" title={userAgent}></i>;
    } else if (ua.includes('windows nt')) {
      return <i className="fab fa-windows text-primary" title={userAgent}></i>;
    } else if (ua.includes('linux')) {
      return <i className="fab fa-linux text-warning" title={userAgent}></i>;
    } else if (ua.includes('mobile')) {
      return <i className="fas fa-mobile-alt" title={userAgent}></i>;
    } else {
      return <i className="fas fa-desktop" title={userAgent}></i>;
    }
  };

  // Function to get device name for display in tooltip and export
  const getDeviceName = (userAgent) => {
    const ua = userAgent.toLowerCase();
    
    if (ua.includes('iphone')) {
      return 'iPhone';
    } else if (ua.includes('ipad')) {
      return 'iPad';
    } else if (ua.includes('ipod')) {
      return 'iPod';
    } else if (ua.includes('mac os')) {
      return 'Mac';
    } else if (ua.includes('android')) {
      return 'Android';
    } else if (ua.includes('windows phone')) {
      return 'Windows Phone';
    } else if (ua.includes('windows nt')) {
      return 'Windows PC';
    } else if (ua.includes('linux')) {
      return 'Linux';
    } else if (ua.includes('mobile')) {
      return 'Mobile Device';
    } else {
      return 'Desktop/Laptop';
    }
  };

  // Function to determine browser icon based on user agent string
  const getBrowserIcon = (userAgent) => {
    const ua = userAgent.toLowerCase();
    
    if (ua.includes('firefox')) {
      return <i className="fab fa-firefox text-warning" title={userAgent}></i>;
    } else if (ua.includes('edge') || ua.includes('edg/')) {
      return <i className="fab fa-edge text-primary" title={userAgent}></i>;
    } else if (ua.includes('chrome') && !ua.includes('edg/')) {
      return <i className="fab fa-chrome text-success" title={userAgent}></i>;
    } else if (ua.includes('safari') && !ua.includes('chrome')) {
      return <i className="fab fa-safari text-info" title={userAgent}></i>;
    } else if (ua.includes('opera') || ua.includes('opr/')) {
      return <i className="fab fa-opera text-danger" title={userAgent}></i>;
    } else if (ua.includes('msie') || ua.includes('trident')) {
      return <i className="fab fa-internet-explorer text-primary" title={userAgent}></i>;
    } else {
      return <i className="fas fa-globe" title={userAgent}></i>;
    }
  };

  // Function to get browser name for display in tooltip
  const getBrowserName = (userAgent) => {
    const ua = userAgent.toLowerCase();
    
    if (ua.includes('firefox')) {
      return 'Firefox';
    } else if (ua.includes('edge') || ua.includes('edg/')) {
      return 'Edge';
    } else if (ua.includes('chrome') && !ua.includes('edg/')) {
      return 'Chrome';
    } else if (ua.includes('safari') && !ua.includes('chrome')) {
      return 'Safari';
    } else if (ua.includes('opera') || ua.includes('opr/')) {
      return 'Opera';
    } else if (ua.includes('msie') || ua.includes('trident')) {
      return 'Internet Explorer';
    } else {
      return 'Unknown Browser';
    }
  };

  const exportToCSV = () => {
    if (!deviceData || deviceData.length === 0) return;

    // Define CSV headers
    const headers = [
      'User ID', 
      'Username', 
      'Last Accessed', 
      'Device Type', 
      'Browser Used', 
      'Location Enabled', 
      'Latitude', 
      'Longitude'
    ];

    // Create CSV content
    const csvContent = [
      headers.join(','),
      ...deviceData.map(record => [
        record.user_id,
        `"${record.user_name}"`, // Wrap in quotes to handle commas in email
        `"${formatDate(record.last_accessed)}"`,
        `"${record.device_type}"`,
        `"${record.browser_used}"`,
        record.location_enabled,
        record.latitude,
        record.longitude
      ].join(','))
    ].join('\n');

    // Create blob and download link
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `device-audit-${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container className="device-audit-container my-4">
      <h2>User Device Audit</h2>
      
      {error && <Alert variant="danger">{error}</Alert>}
      
      <div className="d-flex justify-content-end mb-3">
        <Button 
          variant="primary" 
          onClick={exportToCSV}
          disabled={loading || deviceData.length === 0}
        >
          Export to CSV
        </Button>
      </div>

      {loading ? (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="table-responsive">
          <Table striped bordered hover ref={tableRef}>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Username</th>
                <th>Last Login</th>
                <th>Device</th>
                <th>Browser</th>
                <th>Loc</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {deviceData.length > 0 ? (
                deviceData.map((record, index) => (
                  <tr key={index}>
                    <td>{record.user_id}</td>
                    <td>{record.user_name}</td>
                    <td>{formatDate(record.last_accessed)}</td>
                    <td className="device-column text-center">
                      <span className="fa-2x" data-toggle="tooltip" title={record.device_type}>
                        {getDeviceIcon(record.device_type)}
                      </span>
                    </td>
                    <td className="browser-column text-center">
                      <span className="fa-2x" data-toggle="tooltip" title={record.browser_used}>
                        {getBrowserIcon(record.browser_used)}
                      </span>
                    </td>
                    <td className="text-center">
                      {record.location_enabled ? (
                        <i className="fas fa-check-circle text-success fa-lg"></i>
                      ) : (
                        <i className="fas fa-times-circle text-danger fa-lg"></i>
                      )}
                    </td>
                    <td>
                      {record.location_enabled ? (
                        <a 
                          href={`https://www.google.com/maps?q=${record.latitude},${record.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View ({record.latitude.toFixed(4)}, {record.longitude.toFixed(4)})
                        </a>
                      ) : (
                        "Location not available"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">No device data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default DeviceAudit;
