import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { callMsGraph } from './graph';
import UserContext from './components/context/UserContext';
import axios from 'axios';

const UserProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [user, setUser] = useState(null);

  // When MS SSO status changes, fetch graph data
  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      const fetchUserDataAndPicture = async (accessToken) => {
        try {
          const userData = await callMsGraph(accessToken, 'me');
          setGraphData(userData);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      instance.acquireTokenSilent(request)
        .then((response) => fetchUserDataAndPicture(response.accessToken))
        .catch((e) => {
          instance.acquireTokenPopup(request)
            .then((response) => fetchUserDataAndPicture(response.accessToken))
            .catch((error) => console.error("Error acquiring token:", error));
        });
    } else {
      if (user !== null) {
        setGraphData(null);
      }
    }
  }, [isAuthenticated]);

  // When graphData is available, get user role info from DB
  useEffect(() => {
    if (graphData !== null) {
      axios.get(
        `https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_user_role?user_name=${graphData.userPrincipalName}`,
        { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
      ).then(res => {
        setUser({
          role_id: res.data.role_id,
          role_name: res.data.role_name,
          user_id: res.data.user_id,
          email: graphData.userPrincipalName,
          display_name: graphData.displayName,
          microsoft_id: graphData.id
        });
      })
      .catch((e) => {
        console.error("Error fetching user role data:", e);
        if (e.response?.data?.error === "User not found") {
          console.log("User not found - attempting to create new user");
          axios.get(
            `https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/create_user?user_name=${graphData.userPrincipalName}`,
            { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
          ).then(res => {
            if (res.data.message === "User created successfully") {
              window.location.reload();
            }
          })
          .catch((err) => {
            console.error("Error creating new user:", err.response?.data?.error);
          });
        }
      });
    } else {
      console.log("graphData is null");
    }
  }, [graphData]);

  // New effect to log device/browser and location info
  useEffect(() => {
    if (graphData !== null) {
      // Use the userAgent as a simple way to get device and browser info.
      const device_type = navigator.userAgent;
      const browser_used = navigator.userAgent;
      // Set default location values
      let location_enabled = false;
      let latitude = 0;
      let longitude = 0;

      // Function to post the device and location data
      const postDeviceInfo = (locEnabled, lat, lon) => {
        axios.post(
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/log_user_last_access',
          {
            user_name: graphData.userPrincipalName,
            device_type,
            browser_used,
            location_enabled: locEnabled,
            latitude: lat,
            longitude: lon
          },
          { headers: { "x-api-key": process.env.REACT_APP_CONEXCS_API_KEY } }
        ).then(res => {
          console.log("User device logged successfully:", res.data);
        }).catch(err => {
          console.error("Error logging user device:", err);
        });
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            location_enabled = true;
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            postDeviceInfo(location_enabled, latitude, longitude);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
            postDeviceInfo(false, 0, 0);
          }
        );
      } else {
        // Geolocation not supported
        postDeviceInfo(false, 0, 0);
      }
    }
  }, [graphData]);

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
