import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal, Button, Spinner } from 'react-bootstrap';

/**
 * AppointmentNotes.jsx
 *
 * Props:
 *  - serviceOrderID: string (e.g. "FS000006"), used to fetch/update notes
 *  - username: string, the username (an email address) of the current user
 *  - appointment: string/object, representing the current appointment
 *
 * This component fetches two note fields:
 *  1. UsrIntNotes (internal notes)
 *  2. note (invoice notes, which can also contain HTML)
 *
 * In this update, users cannot edit the existing notes. Instead, they view
 * the current notes and then add their new additions below.
 */

// Simple helper function to strip HTML tags and preserve newlines.
function convertHtmlToPlainText(htmlString) {
  if (!htmlString) return '';
  // Replace <br> with newline
  let result = htmlString.replace(/<br\s*[/]?>/gi, '\n');
  // Remove other HTML tags
  result = result.replace(/<[^>]+>/g, '');
  return result;
}

// Helper: Parse email address to get first and last name (assumes "firstname.lastname@...")
function parseEmailToName(email) {
  if (!email) return "";
  const localPart = email.split('@')[0];
  const nameParts = localPart.split('.');
  if (nameParts.length < 2) return email; // fallback if format isn't as expected
  const firstName = nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
  const lastName = nameParts[1].charAt(0).toUpperCase() + nameParts[1].slice(1);
  return `${firstName} ${lastName}`;
}

// Helper: Generate signature stamp using current date and user's full name derived from email.
function getSignatureStamp(username) {
  if (!username) return "";
  const fullName = parseEmailToName(username);
  const now = new Date();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  
  return `-${month}/${day} ${fullName}:`;
}

/**
 * NEW HELPER: Append the new note addition to the existing note and also
 * append a signature stamp at the beginning of the new addition.
 * This helper differentiates between HTML content and plain text.
 */
function appendNewContent(original, addition, username) {
  if (!addition || !addition.trim()) return original;
  const stamp = getSignatureStamp(username);
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(original);
  if (isHTML) {
    // Put the stamp above the new addition.
    return original + "<p><br/></p>" + `<p>${stamp}</p>` + "<p><br/></p>" + `<p>${addition}</p>`;
  } else {
    return original + "\n\n" + stamp + "\n\n" + addition;
  }
}

const AppointmentNotes = ({ serviceOrderID, username, appointment }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // The fetched data for these notes, as HTML (for internal) or plain text (for invoice).
  const [usrIntNotesContent, setUsrIntNotesContent] = useState('');
  const [noteContent, setNoteContent] = useState('');

  // Which field is active in the modal: 'UsrIntNotes' or 'note' or null.
  const [openModalFor, setOpenModalFor] = useState(null);

  // Saving indicator.
  const [saving, setSaving] = useState(false);

  // NEW: State for the new note addition the user enters.
  const [newAddition, setNewAddition] = useState('');

  // API key.
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;

  // ------------------------------------------------
  //  1) Format the service order ID for fetching/updating
  // ------------------------------------------------
  const numericID = serviceOrderID.replace(/^FS/i, '');
  const numericIDForGet = numericID.padStart(6, '0');
  const fsIDForUpdate = serviceOrderID;

  // -----------------------------------------------
  // 2) Fetch notes from the GET API on mount
  // -----------------------------------------------
  useEffect(() => {
    const fetchNotes = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so',
          {
            method: 'POST',
            headers: {
              'x-api-key': API_KEY,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ serviceOrderID: numericIDForGet }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        }

        const jsonData = await response.json();
        const parsedBody = JSON.parse(jsonData.body);
        if (!Array.isArray(parsedBody) || parsedBody.length < 1) {
          throw new Error('No service order data returned.');
        }

        const soData = parsedBody[0];
        const intNotes = soData?.additionalFields?.UsrIntNotes || '';
        const rawInvoiceNote = soData?.additionalFields?.note || '';
        const invoiceNotePlain = convertHtmlToPlainText(rawInvoiceNote);

        setUsrIntNotesContent(intNotes);
        setNoteContent(invoiceNotePlain);
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (serviceOrderID) {
      fetchNotes();
    }
  }, [serviceOrderID, numericIDForGet, API_KEY]);

  // -----------------------------------------------
  // 3) Open/close modal for note additions
  // -----------------------------------------------
  const handleOpenModal = (field) => {
    // Do not let users edit existing notes – only add to them.
    // Reset the new note addition.
    setNewAddition('');
    setOpenModalFor(field);
  };

  const handleCloseModal = () => {
    setOpenModalFor(null);
  };

  // -----------------------------------------------
  // 4) Saving appended notes
  // -----------------------------------------------
  const handleSaveModal = async () => {
    if (!openModalFor) return;
    if (!newAddition.trim()) {
      alert("Please add some new content before saving.");
      return;
    }
    setSaving(true);
    try {
      const isUsrIntNotes = openModalFor === 'UsrIntNotes';
      const updatedNotes = isUsrIntNotes
        ? appendNewContent(usrIntNotesContent, newAddition, username)
        : appendNewContent(noteContent, newAddition, username);
      const updateField = isUsrIntNotes ? 'UsrIntNotes' : 'note';

      const payload = {
        serviceOrderNumber: fsIDForUpdate,
        updatedNotes,
        updateField,
        username,
        appointment,
        UserType: "Tech",
        Addition: newAddition.trim(),
      };

      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_notes',
        {
          method: 'POST',
          headers: {
            'x-api-key': API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Update failed. Status: ${response.status}`);
      }

      console.log('Update success');

      // Update state with the newly appended note
      if (isUsrIntNotes) {
        setUsrIntNotesContent(updatedNotes);
      } else {
        setNoteContent(updatedNotes);
      }
      handleCloseModal();
    } catch (err) {
      console.error(err);
      alert('Error updating notes: ' + err.message);
    } finally {
      setSaving(false);
    }
  };

  // -----------------------------------------------
  // 5) Render
  // -----------------------------------------------
  if (!serviceOrderID) {
    return <div style={{ margin: 20 }}>No Service Order selected.</div>;
  }

  if (loading) {
    return <div style={{ margin: 20 }}>Loading notes...</div>;
  }

  if (error) {
    return (
      <div style={{ margin: 20, color: 'red' }}>
        Error fetching notes: {error}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 20 }}>
      <h4>Notes for {serviceOrderID}</h4>

      {/* Card #1 - Invoice Note */}
      <div className="card mb-3">
        <div className="card-header" style={{ backgroundColor: '#0066cc', color: 'white' }}>
          <strong>Work Description - Invoice Notes</strong>
        </div>
        <div className="card-body">
          <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            {noteContent}
          </pre>
          <div className="mt-3">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleOpenModal('note')}
            >
              Add to Invoice Notes
            </button>
          </div>
        </div>
      </div>

      {/* Card #2: UsrIntNotes (Internal) */}
      <div className="card">
        <div className="card-header" style={{ backgroundColor: '#0066cc', color: 'white' }}>
          <strong>Internal Notes</strong>
        </div>
        <div className="card-body">
          <div
            style={{ maxHeight: '200px', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: usrIntNotesContent }}
          />
          <div className="mt-3">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleOpenModal('UsrIntNotes')}
            >
              Add to Internal Notes
            </button>
          </div>
        </div>
      </div>

      {/* Modal for adding new note content */}
      <Modal show={!!openModalFor} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {openModalFor === 'UsrIntNotes' ? 'Add to Internal Notes' : 'Add to Invoice Notes'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {openModalFor === 'UsrIntNotes' ? (
            <>
              <div>
                <strong>Existing Internal Notes:</strong>
                <div
                  style={{
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginBottom: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto'
                  }}
                  dangerouslySetInnerHTML={{ __html: usrIntNotesContent }}
                />
              </div>
              <div>
                <strong>Add New Internal Note:</strong>
                <ReactQuill
                  key="newUsrIntNotes"
                  value={newAddition}
                  onChange={setNewAddition}
                  theme="snow"
                  style={{ height: '200px' }}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                <strong>Existing Invoice Notes:</strong>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    margin: 0,
                    border: '1px solid #ccc',
                    padding: '10px',
                    marginBottom: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto'
                  }}
                >
                  {noteContent}
                </pre>
              </div>
              <div>
                <strong>Add New Invoice Note:</strong>
                <textarea
                  key="newInvoiceNote"
                  style={{ width: '100%', height: '200px' }}
                  value={newAddition}
                  onChange={(e) => setNewAddition(e.target.value)}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveModal} disabled={saving}>
            {saving ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Saving...</span>
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AppointmentNotes;
