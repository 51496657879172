import React, { useEffect, useState } from 'react';

const BillingReview = () => {
  const [serviceOrders, setServiceOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceOrders = async () => {
      try {
        const response = await fetch(
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_powerbi_SOs',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY
            }
          }
        );

        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }

        const data = await response.json();
        setServiceOrders(data.serviceOrders || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceOrders();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ padding: '20px' }}>
      <h1>Billing Review</h1>
      <div style={{ overflowX: 'auto', marginTop: '20px' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)'
          }}
        >
          <thead>
            <tr style={{ backgroundColor: '#333', color: '#fff' }}>
              <th style={cellStyle}>Order Type</th>
              <th style={cellStyle}>Order Nbr</th>
              <th style={cellStyle}>Date</th>
              <th style={cellStyle}>Customer</th>
              <th style={cellStyle}>Account Name</th>
              <th style={cellStyle}>Workflow Stage</th>
              <th style={cellStyle}>Status</th>
              <th style={cellStyle}>Branch Location</th>
              <th style={cellStyle}>Estimated Billable Total</th>
              <th style={cellStyle}>Cost Total</th>
              <th style={cellStyle}>Parent Customer</th>
              <th style={cellStyle}>State</th>
              <th style={cellStyle}>Doc Description</th>
              <th style={cellStyle}>Created On</th>
              <th style={cellStyle}>Last Modified On</th>
              <th style={cellStyle}>Customer ID</th>
              <th style={cellStyle}>Address ID</th>
              <th style={cellStyle}>Customer ID 2</th>
            </tr>
          </thead>
          <tbody>
            {serviceOrders.map((order, index) => (
              <tr
                key={index}
                style={index % 2 ? rowStyleOdd : rowStyleEven}
              >
                <td style={cellStyle}>{order.OrderType}</td>
                <td style={cellStyle}>{order.OrderNbr}</td>
                <td style={cellStyle}>{order.Date}</td>
                <td style={cellStyle}>{order.Customer}</td>
                <td style={cellStyle}>{order.AccountName}</td>
                <td style={cellStyle}>{order.WorkflowStage}</td>
                <td style={cellStyle}>{order.Status}</td>
                <td style={cellStyle}>{order.BranchLocation}</td>
                <td style={cellStyle}>{order.EstimatedBillableTotal}</td>
                <td style={cellStyle}>{order.CostTotal}</td>
                <td style={cellStyle}>{order.ParentCustomer}</td>
                <td style={cellStyle}>{order.State}</td>
                <td style={cellStyle}>{order.DocDescription}</td>
                <td style={cellStyle}>{order.CreatedOn}</td>
                <td style={cellStyle}>{order.LastModifiedOn}</td>
                <td style={cellStyle}>{order.CustomerID}</td>
                <td style={cellStyle}>{order.AddressID}</td>
                <td style={cellStyle}>{order.CustomerID_2}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const cellStyle = {
  padding: '12px',
  border: '1px solid #ddd',
  textAlign: 'left'
};

const rowStyleEven = {
  backgroundColor: '#f9f9f9'
};

const rowStyleOdd = {
  backgroundColor: '#fff'
};

export default BillingReview;
