// Menu items shared between LeftSideMenu and MobileFooter
const menuItems = [
    { name: 'Home', icon: 'fas fa-home', path: '/', role: 0 },
    // Techroom
    { name: 'On Call Calendar', icon: 'fas fa-calendar-alt', path: '/CalendarOnCall', role: 0, category: 'Techroom' },
    { name: 'Request PTO', icon: 'fas fa-calendar-plus', path: 'https://app.smartsheet.com/b/form/5a919dee44034735a03e9f9c70fcfa33', isExternal: true, role: 0, category: 'Techroom' },
    { name: 'Phone List', icon: 'fas fa-phone', path: 'https://www.appsheet.com/start/1e3f4aef-38f8-43d6-becb-70245f81036b', isExternal: true, role: 0, category: 'Techroom' },
    { name: 'Training', icon: 'fas fa-graduation-cap', path: '/Training', role: 0, category: 'Techroom' },
    { name: 'Water Heater', icon: 'fas fa-hot-tub', path: 'https://app.smartsheet.com/b/form/e7861e6197194093ad2312ef81b317ae', isExternal: true, role: 0, category: 'Techroom' },
    { name: 'Create Service Order', icon: 'fas fa-clipboard-list', path: '/create-service-order', role: 1, category: 'Techroom' },
    { name: 'Appointments', icon: 'fas fa-calendar-check', path: '/AppointmentWorkFlow', role: 1, category: 'Techroom' },
    
    // Operations
    { name: 'PTO Calendar', icon: 'fas fa-calendar', path: 'https://app.smartsheet.com/b/publish?EQBCT=cb06343e7f8842029dc194824813e5cd', isExternal: true, role: 2, category: 'Operations' },
    { name: 'Service Orders', icon: 'fas fa-search', path: '/ServiceOrderLookup', role: 2, category: 'Operations' },
    { name: 'Manage POs', icon: 'fas fa-file-invoice-dollar', path: '/ManagePOs', role: 2, category: 'Operations' },
    { name: 'Dispatcher Board', icon: 'fas fa-tasks', path: '/DispatcherBoard', role: 2, category: 'Operations' },
    { name: 'Tech Time Off', icon: 'fas fa-user-clock', path: '/TechnicianTimeOff', role: 2, category: 'Operations' },
    { name: 'New Employee Setup', icon: 'fas fa-user-plus', path: '/NewEmployeeSetup', role: 2, category: 'Operations' },
    
    // IT Admin
    { name: 'User Admin', icon: 'fas fa-users-cog', path: '/UserAdmin', role: 9, category: 'IT Admin' },
    { name: 'UFC', icon: 'fas fa-cog', path: '/UFC', role: 9, category: 'IT Admin' },
    { name: 'Customer Accounts', icon: 'fas fa-building', path: '/customeraccounts', role: 9, category: 'IT Admin' },
    { name: 'Billing Review', icon: 'fas fa-file-invoice', path: '/billingreview', role: 9, category: 'IT Admin' },
    { name: 'Device Audit', icon: 'fas fa-mobile-alt', path: '/deviceaudit', role: 9, category: 'IT Admin' },
  ];
  
  export default menuItems;