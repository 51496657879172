import React, { useState, useEffect } from 'react';
import './css/Weather.css';

const Weather = () => {
  const [location, setLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiKeyError, setApiKeyError] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [error, setError] = useState(null);

  // Check if API key exists (without logging the full key for security)
  const apiKey = process.env.REACT_APP_OPENWEATHER_API_KEY;
  
  useEffect(() => {
    if (!apiKey) {
      setApiKeyError("API key is missing. Please check your environment variables.");
      console.error("OpenWeather API key is missing");
    } else {
      console.log("API key is present (first 4 chars):", apiKey.substring(0, 4) + "...");
    }
  }, [apiKey]);

  useEffect(() => {
    // Try to get user's location with a longer timeout
    const getLocation = async () => {
      // Fallback to Scottsdale, Arizona as default location
      const defaultLocation = {
        lat: 33.4942, 
        lon: -111.9261
      };
      
      if (!navigator.geolocation) {
        console.log("Geolocation not supported, using default location");
        setLocationError("Geolocation is not supported by your browser. Using default location.");
        setLocation(defaultLocation);
        setLoading(false);
        return;
      }
      
      // Create a promise that will timeout
      const locationPromise = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: false,
          timeout: 15000, // Increased timeout to 15 seconds
          maximumAge: 60000 // Cache location for 1 minute
        });
      });
      
      try {
        // Wait for location or timeout
        const position = await Promise.race([
          locationPromise,
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error("Location request timed out")), 15500)
          )
        ]);
        
        const locationData = {
          lat: position.coords.latitude,
          lon: position.coords.longitude
        };
        console.log("Got user location:", locationData);
        setLocation(locationData);
      } catch (err) {
        console.error("Error getting location:", err);
        setLocationError("Could not access your location. Using Scottsdale, AZ as default.");
        setLocation(defaultLocation);
      } finally {
        setLoading(false);
      }
    };
    
    getLocation();
  }, []);

  // Get city name from coordinates
  useEffect(() => {
    const getLocationName = async () => {
      if (!location || !apiKey) return;
      
      try {
        // Use reverse geocoding to get city name
        const response = await fetch(
          `https://api.openweathermap.org/geo/1.0/reverse?lat=${location.lat}&lon=${location.lon}&limit=1&appid=${apiKey}`
        );
        
        if (!response.ok) {
          throw new Error(`Geocoding API error: ${response.status}`);
        }
        
        const data = await response.json();
        if (data && data.length > 0) {
          const cityName = data[0].name;
          const stateName = data[0].state || '';
          const cityDisplay = stateName ? `${cityName}, ${stateName}` : cityName;
          console.log("Location name:", cityDisplay);
          setLocationName(cityDisplay);
        } else {
          // Fallback for Scottsdale if geocoding doesn't return results
          if (Math.abs(location.lat - 33.4942) < 0.01 && Math.abs(location.lon - (-111.9261)) < 0.01) {
            setLocationName("Scottsdale, AZ");
          } else {
            setLocationName("Your Location");
          }
        }
      } catch (err) {
        console.error("Error fetching location name:", err);
        // Fallback for Scottsdale
        if (Math.abs(location.lat - 33.4942) < 0.01 && Math.abs(location.lon - (-111.9261)) < 0.01) {
          setLocationName("Scottsdale, AZ");
        } else {
          setLocationName("Your Location");
        }
      }
    };

    getLocationName();
  }, [location, apiKey]);

  // Fetch weather data when location is available
  useEffect(() => {
    const fetchWeatherData = async () => {
      if (!location || !apiKey) return;
      
      try {
        // Using the new One Call API 3.0 as mentioned in the email
        const response = await fetch(
          `https://api.openweathermap.org/data/3.0/onecall?lat=${location.lat}&lon=${location.lon}&appid=${apiKey}&units=imperial&exclude=minutely,hourly,alerts`
        );
        
        if (!response.ok) {
          if (response.status === 429) {
            throw new Error("API call limit reached. Please try again later.");
          } else {
            throw new Error(`Weather API error: ${response.status}`);
          }
        }
        
        const data = await response.json();
        console.log("Weather data received:", data);
        setWeatherData(data);
      } catch (err) {
        console.error("Error fetching weather:", err);
        setError(err.message);
      }
    };

    fetchWeatherData();
  }, [location, apiKey]);

  if (apiKeyError) {
    return (
      <div className="weather-container">
        <div className="weather-error">
          <i className="fas fa-exclamation-triangle"></i>
          <p>{apiKeyError}</p>
          <p>Please check the OpenWeather API key configuration.</p>
        </div>
      </div>
    );
  }

  if (loading || !location) {
    return (
      <div className="weather-container">
        <div className="weather-loading">
          <i className="fas fa-spinner fa-pulse"></i>
          <p>Loading weather data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="weather-container">
        <div className="weather-error">
          <i className="fas fa-exclamation-circle"></i>
          <p>Could not load weather data: {error}</p>
          <p><small>This could be due to an invalid API key or rate limits. New API keys may take a few hours to activate.</small></p>
        </div>
      </div>
    );
  }

  if (!weatherData) {
    return (
      <div className="weather-container">
        <div className="weather-loading">
          <i className="fas fa-spinner fa-pulse"></i>
          <p>Fetching weather data...</p>
        </div>
      </div>
    );
  }

  // Format date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
  };

  // Get weather icon URL
  const getIconUrl = (iconCode) => {
    return `https://openweathermap.org/img/wn/${iconCode}@2x.png`;
  };

  return (
    <div className="weather-container">
      {locationError && (
        <div className="location-error">
          <p>{locationError}</p>
        </div>
      )}
      
      {/* Current Weather */}
      <div className="current-weather">
        <div className="current-header">
          <h3>{locationName || "Your Location"}</h3>
          <p className="current-date">{formatDate(weatherData.current.dt)}</p>
        </div>
        
        <div className="current-details">
          <div className="temp-icon">
            <img 
              src={getIconUrl(weatherData.current.weather[0].icon)} 
              alt={weatherData.current.weather[0].description} 
              className="weather-icon"
            />
            <span className="current-temp">{Math.round(weatherData.current.temp)}°F</span>
          </div>
          
          <div className="weather-description">
            <p className="weather-main">{weatherData.current.weather[0].main}</p>
            <p className="weather-desc">{weatherData.current.weather[0].description}</p>
          </div>
        </div>
        
        <div className="weather-details">
          <div className="detail-item">
            <i className="fas fa-thermometer-half"></i>
            <span>Feels Like: {Math.round(weatherData.current.feels_like)}°F</span>
          </div>
          <div className="detail-item">
            <i className="fas fa-tint"></i>
            <span>Humidity: {weatherData.current.humidity}%</span>
          </div>
          <div className="detail-item">
            <i className="fas fa-wind"></i>
            <span>Wind: {Math.round(weatherData.current.wind_speed)} mph</span>
          </div>
          <div className="detail-item">
            <i className="fas fa-sun"></i>
            <span>UV Index: {Math.round(weatherData.current.uvi)}</span>
          </div>
        </div>
      </div>
      
      {/* Forecast */}
      <div className="forecast">
        <h4>8-Day Forecast</h4>
        <div className="forecast-items">
          {weatherData.daily.slice(0, 7).map((day, index) => (
            <div className="forecast-day" key={index}>
              <p className="forecast-date">{formatDate(day.dt)}</p>
              <img 
                src={getIconUrl(day.weather[0].icon)} 
                alt={day.weather[0].description}
                className="forecast-icon" 
              />
              <div className="forecast-temps">
                <span className="max-temp">{Math.round(day.temp.max)}°</span>
                <span className="min-temp">{Math.round(day.temp.min)}°</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Weather;
