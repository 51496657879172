// App.js

import React, { useContext, useEffect, useState } from 'react';
import { PageLayout } from './components/PageLayout';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';

import UserContext from './components/context/UserContext';
import Home from './components/Home';
import { ServiceOrderProvider } from './components/context/ServiceOrderContext';
import { PartsProvider } from './components/context/PartsContext';
import NavLinks from './components/NavLinks';
import CompanyChatWidget from './components/CompanyChatWidget';
import MobileFooter from './components/MobileFooter';

// Create a Role Emulation Context at the App level
export const AppRoleEmulationContext = React.createContext();

function App() {
  const { user } = useContext(UserContext);
  const [effectiveRole, setEffectiveRole] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Check for mobile screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Force a refresh when localStorage changes
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'effectiveRole') {
        // Handle '0' string value correctly
        setEffectiveRole(e.newValue !== null ? parseInt(e.newValue, 10) : null);
      }
    };

    // Check localStorage on mount - handle '0' string value correctly
    const storedRole = localStorage.getItem('effectiveRole');
    if (storedRole !== null) {
      setEffectiveRole(parseInt(storedRole, 10));
    } else if (user?.role_id !== undefined) {
      setEffectiveRole(user.role_id);
    }

    // Set up a storage event listener
    window.addEventListener('storage', handleStorageChange);
    
    // Also check periodically in case storage event doesn't fire
    const interval = setInterval(() => {
      const currentStoredRole = localStorage.getItem('effectiveRole');
      if (currentStoredRole !== null && parseInt(currentStoredRole, 10) !== effectiveRole) {
        setEffectiveRole(parseInt(currentStoredRole, 10));
      }
    }, 1000);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, [user, effectiveRole]);

  // Create context value - handle 0 role correctly with strict checks
  const roleEmulationValue = {
    effectiveRole: effectiveRole !== null ? effectiveRole : user?.role_id,
    setEffectiveRole: (newRole) => {
      // Explicitly log the value to debug
      console.log("App: Setting effectiveRole to:", newRole);
      setEffectiveRole(newRole);
      localStorage.setItem('effectiveRole', newRole.toString());
    }
  };

  return (
    <Router>
      <AppRoleEmulationContext.Provider value={roleEmulationValue}>
        <PageLayout>
          <AuthenticatedTemplate>
            {user !== null ? (
              <>
                <ServiceOrderProvider>
                  <PartsProvider>
                    {/* Pass a key to NavLinks to force re-render when role changes */}
                    <NavLinks key={`navlinks-${roleEmulationValue.effectiveRole}`} />
                    <CompanyChatWidget />
                    
                    {/* Move MobileFooter here to ensure it's available on all routes */}
                    {isMobile && user && <MobileFooter 
                      key={`mobile-footer-${roleEmulationValue.effectiveRole}`} 
                      effectiveRole={roleEmulationValue.effectiveRole} 
                    />}
                  </PartsProvider>
                </ServiceOrderProvider>
              </>
            ) : (
              <Home />
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <p>You are not signed in! Please sign in with your Microsoft account.</p>
          </UnauthenticatedTemplate>
        </PageLayout>
      </AppRoleEmulationContext.Provider>
    </Router>
  );
}

export default App;
