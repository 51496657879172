// src/components/CreateServiceOrder.jsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, Form, Button, Spinner, Alert, Container, Row, Col, ListGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/CreateServiceOrder.css';
import UserContext from './context/UserContext'; // Import UserContext instead of AuthContext

export default function CreateServiceOrder() {
  // Get user data from UserContext
  const { user } = useContext(UserContext);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [serviceType, setServiceType] = useState('Plumbing');
  const [marketBranch, setMarketBranch] = useState('');
  const [description, setDescription] = useState('');
  const [serviceOrderType, setServiceOrderType] = useState('ServiceOrder');
  const [charactersLeft, setCharactersLeft] = useState(255);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [validated, setValidated] = useState(false);
  const [showResults, setShowResults] = useState(false);
  
  const searchTimeoutRef = useRef(null);
  const navigate = useNavigate();
  
  // Log current username (user email) on component load
  useEffect(() => {
    // Log both context user and localStorage username for comparison
    console.log("Current user from context:", user?.email);
    const localStorageUsername = localStorage.getItem('username');
    console.log("Current user from localStorage:", localStorageUsername);
    
    // Ensure we have a valid email address for technician
    const technician = user?.email || localStorageUsername || 'acumatica@collins-cs.com';
    console.log("Using technician email:", technician);
  }, [user]);
  
  // Function to fetch customers based on search term
  const searchCustomers = async (term) => {
    if (term.length < 4) {
      setCustomers([]);
      return;
    }
    
    setSearchLoading(true);
    setSearchError(null);
    
    try {
      const response = await axios.post(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_customers',
        { searchString: term },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY
          }
        }
      );
      
      setCustomers(response.data || []);
      setShowResults(true);
    } catch (err) {
      console.error('Error searching customers:', err);
      setSearchError('Failed to search customers. Please try again.');
      setCustomers([]);
    } finally {
      setSearchLoading(false);
    }
  };
  
  // Handle search input changes with debouncing
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    // If search is cleared or less than 4 chars, clear results
    if (value.length < 4) {
      setCustomers([]);
      setShowResults(false);
      return;
    }
    
    // Set new timeout for API call (300ms debounce)
    searchTimeoutRef.current = setTimeout(() => {
      searchCustomers(value);
    }, 300);
  };
  
  // Handle customer selection
  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowResults(false);
    setSearchTerm(customer.CustomerName);
    
    // Determine market branch based on customer state
    const state = customer.State;
    if (state === 'AZ') {
      // For Arizona, we'll set the branch based on service type selection
      setMarketBranch(serviceType === 'Plumbing' ? 'SCOTTSDALE' : 'HVAC');
    } else if (state === 'TX') {
      setMarketBranch('TEXAS');
    } else if (state === 'NV') {
      setMarketBranch('NEVADA');
    } else if (state === 'CO') {
      setMarketBranch('COLO');
    } else if (state === 'NC') {
      setMarketBranch('NORTH CAROLINA');
    } else {
      setMarketBranch('OM');
    }
  };
  
  // Handle service type change (for Arizona customers)
  const handleServiceTypeChange = (type) => {
    setServiceType(type);
    // Update market branch if customer is from Arizona
    if (selectedCustomer && selectedCustomer.State === 'AZ') {
      setMarketBranch(type === 'Plumbing' ? 'SCOTTSDALE' : 'HVAC');
    }
  };
  
  // Handle description change
  const handleDescriptionChange = (e) => {
    const text = e.target.value;
    if (text.length <= 255) {
      setDescription(text);
      setCharactersLeft(255 - text.length);
    }
  };
  
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false || !selectedCustomer) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Get current user's email (username) from context or localStorage
      const userEmail = user?.email || localStorage.getItem('username') || 'acumatica@collins-cs.com';
      console.log("Creating service order with technician email:", userEmail);
      
      // Prepare payload for service order creation
      const payload = {
        "ServiceOrderType": "WO2",
        "Customer": selectedCustomer.CustomerID,
        "Date": new Date().toISOString(),
        "Location": "MAIN",
        "BranchLocation": marketBranch,
        "Project": "X",
        "Description": description,
        "TechnicianEmail": userEmail,
        "CreateAppointment": 1
      };
      
      // Make API call to create service order
      const response = await axios.post(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/create_service_order',
        payload,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_CONEXCS_API_KEY
          }
        }
      );
      
      console.log("Service order created:", response.data);
      setSuccess('Service order created successfully!');
      
      // Clear form after successful submission
      setSelectedCustomer(null);
      setSearchTerm('');
      setDescription('');
      setServiceOrderType('ServiceOrder');
      setValidated(false);
      
      // After 2 seconds, you could redirect to the new service order
      setTimeout(() => {
        // navigate(`/ServiceOrderDetails/${response.data.ServiceOrderID}`); // Example navigation
        setSuccess(null);
      }, 2000);
      
    } catch (err) {
      console.error('Error creating service order:', err);
      setError('Failed to create service order. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);
  
  // Close results dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.customer-search-container')) {
        setShowResults(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <Container className="create-service-order-container">
      <Card className="mt-3 mb-3 service-order-card">
        <Card.Header as="h2" className="text-center">Create Service Order</Card.Header>
        <Card.Body>
          {/* Breadcrumb Navigation */}
          <nav className="breadcrumb-nav mb-4">
            <Link to="/AppointmentWorkFlow" className="breadcrumb-link">Appointment Dashboard</Link>
            <span className="breadcrumb-separator">/</span>
            <span className="breadcrumb-current">Create Service Order</span>
          </nav>
          
          {/* Success and Error Messages */}
          {success && <Alert variant="success" className="mb-3">{success}</Alert>}
          {error && <Alert variant="danger" className="mb-3">{error}</Alert>}
          
          {/* Form */}
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* Customer Section */}
            <div className="form-section">
              <div className="section-header">
                <h4>Customer Information</h4>
              </div>
              
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={12} md={3} className="form-label">
                  Customer Search *
                </Form.Label>
                <Col sm={12} md={9} className="customer-search-container">
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      placeholder="Search for a customer (min 4 characters)..."
                      required={!selectedCustomer}
                      disabled={loading}
                      className="customer-search-input"
                      autoComplete="off"
                    />
                    {searchLoading && (
                      <div className="position-absolute end-0 top-0 pe-3 pt-2">
                        <Spinner animation="border" size="sm" />
                      </div>
                    )}
                    
                    {showResults && customers.length > 0 && (
                      <ListGroup className="customer-search-results">
                        {customers.map((customer, index) => (
                          <ListGroup.Item 
                            key={`${customer.CustomerID}-${index}`}
                            action
                            onClick={() => handleSelectCustomer(customer)}
                            className="customer-result-item"
                          >
                            <div className="customer-name">{customer.CustomerName}</div>
                            <div className="customer-location">
                              {customer.City}{customer.City && customer.State ? ', ' : ''}{customer.State}
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                    
                    {showResults && customers.length === 0 && !searchLoading && searchTerm.length >= 4 && (
                      <div className="no-results-message">
                        No customers found matching "{searchTerm}"
                      </div>
                    )}
                  </div>
                  
                  {searchError && (
                    <div className="text-danger mt-2 small">{searchError}</div>
                  )}
                  
                  <Form.Control.Feedback type="invalid">
                    Please select a customer.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              
              {/* Selected Customer Card */}
              {selectedCustomer && (
                <Row className="mb-3">
                  <Col sm={12} md={{ span: 9, offset: 3 }}>
                    <Card className="selected-customer-card">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-start">
                          <div>
                            <h5 className="mb-1">{selectedCustomer.CustomerName}</h5>
                            <p className="mb-1">ID: {selectedCustomer.CustomerID}</p>
                            <p className="mb-1">
                              {selectedCustomer.AddressLine1}
                              {selectedCustomer.AddressLine1 ? <br /> : ''}
                              {selectedCustomer.City}{selectedCustomer.City && selectedCustomer.State ? ', ' : ''}
                              {selectedCustomer.State}
                            </p>
                            <div className="customer-details mt-2">
                              <span className={`status-badge ${selectedCustomer.CustomerStatus === 'Active' ? 'status-active' : 'status-inactive'}`}>
                                {selectedCustomer.CustomerStatus}
                              </span>
                              {selectedCustomer.Terms && (
                                <span className="terms-badge ms-2">
                                  Terms: {selectedCustomer.Terms}
                                </span>
                              )}
                            </div>
                          </div>
                          <Button 
                            variant="outline-secondary" 
                            size="sm"
                            onClick={() => {
                              setSelectedCustomer(null);
                              setSearchTerm('');
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
            
            {/* Service Details Section (only shown after customer selection) */}
            {selectedCustomer && (
              <div className="form-section">
                <div className="section-header">
                  <h4>Service Details</h4>
                </div>
                
                {/* Service Type Selection (only for Arizona customers) */}
                {selectedCustomer.State === 'AZ' && (
                  <Form.Group as={Row} className="mb-4">
                    <Form.Label column sm={12} md={3} className="form-label">
                      Service Type *
                    </Form.Label>
                    <Col sm={12} md={9}>
                      <div className="radio-container">
                        <div className="custom-radio-item">
                          <input
                            type="radio"
                            id="plumbing-service-type"
                            name="serviceType"
                            value="Plumbing"
                            checked={serviceType === 'Plumbing'}
                            onChange={() => handleServiceTypeChange('Plumbing')}
                            disabled={loading}
                          />
                          <label htmlFor="plumbing-service-type">Plumbing</label>
                        </div>
                        
                        <div className="custom-radio-item">
                          <input
                            type="radio"
                            id="hvac-service-type"
                            name="serviceType"
                            value="HVAC"
                            checked={serviceType === 'HVAC'}
                            onChange={() => handleServiceTypeChange('HVAC')}
                            disabled={loading}
                          />
                          <label htmlFor="hvac-service-type">HVAC</label>
                        </div>
                      </div>
                    </Col>
                  </Form.Group>
                )}
                
                {/* Market Branch Display */}
                <Form.Group as={Row} className="mb-4">
                  <Form.Label column sm={12} md={3} className="form-label">
                    Market Branch
                  </Form.Label>
                  <Col sm={12} md={9}>
                    <p className="form-control-plaintext branch-display">
                      {marketBranch}
                    </p>
                  </Col>
                </Form.Group>
              </div>
            )}
            
            {/* Order Details Section */}
            {selectedCustomer && (
              <div className="form-section">
                <div className="section-header">
                  <h4>Order Details</h4>
                </div>
                
                <Form.Group as={Row} className="mb-4">
                  <Form.Label column sm={12} md={3} className="form-label">
                    Description *
                  </Form.Label>
                  <Col sm={12} md={9}>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={description}
                      onChange={handleDescriptionChange}
                      placeholder="Enter service order description..."
                      required
                      disabled={loading}
                      maxLength={255}
                      className="description-textarea"
                    />
                    <div className="d-flex justify-content-end mt-1">
                      <small className={charactersLeft < 30 ? 'text-danger' : 'text-muted'}>
                        {charactersLeft} characters remaining
                      </small>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please provide a description.
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                
                {/* Type Selection */}
                <Form.Group as={Row} className="mb-4">
                  <Form.Label column sm={12} md={3} className="form-label">
                    Type *
                  </Form.Label>
                  <Col sm={12} md={9}>
                    <div className="radio-container">
                      <div className="custom-radio-item">
                        <input
                          type="radio"
                          id="service-order-type"
                          name="serviceOrderType"
                          value="ServiceOrder"
                          checked={serviceOrderType === 'ServiceOrder'}
                          onChange={() => setServiceOrderType('ServiceOrder')}
                          disabled={loading}
                        />
                        <label htmlFor="service-order-type">Service Order</label>
                      </div>
                      
                      <div className="custom-radio-item">
                        <input
                          type="radio"
                          id="estimate-type"
                          name="serviceOrderType"
                          value="Estimate"
                          checked={serviceOrderType === 'Estimate'}
                          onChange={() => setServiceOrderType('Estimate')}
                          disabled={true}
                        />
                        <label htmlFor="estimate-type">Estimate (disabled)</label>
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </div>
            )}
            
            {/* Submit Section */}
            <div className="form-section submit-section">
              <div className="d-flex justify-content-end">
                <Button 
                  variant="secondary" 
                  as={Link} 
                  to="/AppointmentWorkFlow" 
                  className="me-2 cancel-button"
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit" 
                  variant="primary"
                  disabled={loading || !selectedCustomer}
                  className="submit-button"
                >
                  {loading ? (
                    <>
                      <Spinner 
                        as="span" 
                        animation="border" 
                        size="sm" 
                        role="status" 
                        aria-hidden="true" 
                        className="me-2"
                      />
                      Creating...
                    </>
                  ) : (
                    'Create Service Order'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}