import React, { useState, useEffect, useRef } from 'react';
import './css/ChatWidget.css';

const WEBSOCKET_URL = "wss://1ly0s6cxn4.execute-api.us-east-2.amazonaws.com/production/";

// TEMPORARILY DISABLE CSR CHAT WIDGET:
// Change this to true to re-enable the widget.
const ENABLE_CSR_CHAT = false;

function CompanyChatWidget() {
  // Always call hooks in the same order.
  const [sessions, setSessions] = useState({});
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [input, setInput] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ws = useRef(null);

  // Function to establish a WebSocket connection.
  const connectWebSocket = () => {
    console.log("Establishing WebSocket connection for CSR...");
    const socket = new WebSocket(`${WEBSOCKET_URL}?userType=csr&userId=csr123`);
    ws.current = socket;

    socket.onopen = () => {
      console.log("CSR WebSocket connected");
      // On connection, fetch the list of active chats
      const fetchMsg = { action: "fetchActiveChats" };
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(fetchMsg));
      }
    };

    socket.onmessage = (event) => {
      console.log("Raw message received (CSR):", event.data);
      let data;
      try {
        data = JSON.parse(event.data);
      } catch (err) {
        console.error("Error parsing message:", err);
        return;
      }

      switch (data.action) {
        case "activeChatList":
          if (Array.isArray(data.sessions)) {
            const mappedSessions = {};
            data.sessions.forEach(s => {
              mappedSessions[s.sessionId] = {
                customerName: s.customerName || "Unknown",
                messages: []
              };
            });
            console.log("Received activeChatList:", mappedSessions);
            setSessions(mappedSessions);
          }
          break;

        case "newChat":
          if (data.sessionId) {
            setSessions(prev => {
              const newSessions = { ...prev };
              newSessions[data.sessionId] = {
                customerName: data.customerName || "Unknown",
                messages: [{
                  from: "system",
                  message: data.message || "New chat session started."
                }]
              };
              return newSessions;
            });
            setSelectedSessionId(data.sessionId);
            if (!isOpen) {
              setIsOpen(true);
            }
            console.log("New chat session added:", data.sessionId);
          }
          break;

        case "receiveMessage":
          if (data.sessionId) {
            setSessions(prev => {
              const newSessions = { ...prev };
              // If the session isn't already present, create it using the customerName (if provided)
              if (!newSessions[data.sessionId]) {
                newSessions[data.sessionId] = {
                  customerName: data.customerName || "Unknown",
                  messages: []
                };
              }
              newSessions[data.sessionId].messages.push({
                from: data.from,
                message: data.message
              });
              return newSessions;
            });
            console.log("Received message for session:", data.sessionId);
          }
          break;

        case "endChat":
        case "chatEnded":
          if (data.sessionId) {
            setSessions(prev => {
              const newSessions = { ...prev };
              delete newSessions[data.sessionId];
              return newSessions;
            });
            if (selectedSessionId === data.sessionId) {
              setSelectedSessionId(null);
            }
            console.log("Chat ended for session:", data.sessionId);
          }
          break;

        default:
          console.warn("Unhandled CSR action:", data.action);
          break;
      }
    };

    socket.onerror = (err) => {
      console.error("CSR WebSocket error:", err);
    };

    socket.onclose = (event) => {
      console.log("CSR WebSocket closed:", event.reason || event.code);
      ws.current = null;
      // Reconnect after 5 seconds if widget is enabled.
      if (ENABLE_CSR_CHAT) setTimeout(connectWebSocket, 5000);
    };
  };

  // Call connectWebSocket only if the widget is enabled.
  useEffect(() => {
    if (!ENABLE_CSR_CHAT) return;
    connectWebSocket();
    return () => {
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
    };
  }, []);

  // When the widget is opened, refresh active chats.
  useEffect(() => {
    if (!ENABLE_CSR_CHAT) return;
    if (isOpen && ws.current && ws.current.readyState === WebSocket.OPEN) {
      const fetchMsg = { action: "fetchActiveChats" };
      console.log("Fetching active chats...");
      ws.current.send(JSON.stringify(fetchMsg));
    }
  }, [isOpen]);

  // Helper to ensure the connection is open.
  const ensureWebSocketConnection = () => {
    if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
      console.warn("CSR WebSocket not open. Attempting to reconnect...");
      connectWebSocket();
    }
  };

  // Function to send a message from the CSR.
  const sendMessage = () => {
    if (!selectedSessionId) {
      console.error("No session selected. Cannot send message.");
      return;
    }
    ensureWebSocketConnection();

    const messageData = {
      action: "sendMessage",
      sessionId: selectedSessionId,
      from: "csr",
      message: input,
    };
    console.log("Sending CSR message:", messageData);
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(messageData));
    } else {
      console.warn("WebSocket is not open.");
    }

    setSessions(prev => {
      const newSessions = { ...prev };
      if (newSessions[selectedSessionId]) {
        newSessions[selectedSessionId].messages.push({ from: "csr", message: input });
      }
      return newSessions;
    });
    setInput("");
  };

  // If chat is disabled, render nothing (after calling hooks above).
  if (!ENABLE_CSR_CHAT) return null;

  return (
    <div className="chat-widget-container">
      {/* If the widget is minimized, show a button */}
      {!isOpen && (
        <button
          className="chat-button-style"
          onClick={() => setIsOpen(true)}
        >
          CSR Chat
        </button>
      )}

      {/* When open, show the chat UI */}
      {isOpen && (
        <div className="chat-window-style">
          <div className="chat-header-style">
            <span>Customer Chats</span>
            <button 
              className="close-button-style" 
              onClick={() => setIsOpen(false)}
            >
              –
            </button>
          </div>

          <div className="chat-body-style">
            {/* Sessions list */}
            <div className="chat-sessions-menu">
              {Object.entries(sessions).length === 0 && (
                <div className="no-chats">No active chats</div>
              )}
              {Object.entries(sessions).map(([sid, session]) => (
                <div
                  key={sid}
                  className={`session-item ${selectedSessionId === sid ? "active" : ""}`}
                  onClick={() => setSelectedSessionId(sid)}
                >
                  {session.customerName}
                </div>
              ))}
            </div>

            {/* Chat messages for the selected session */}
            <div className="chat-session-view">
              {selectedSessionId && sessions[selectedSessionId] ? (
                sessions[selectedSessionId].messages.map((msg, index) => (
                  <p key={index}>
                    <strong>{msg.from || "system"}:</strong> {msg.message}
                  </p>
                ))
              ) : (
                <p>Select a session to view messages</p>
              )}
            </div>
          </div>

          {/* Input to send a message */}
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your response..."
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyChatWidget;
