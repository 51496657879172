// updateAppointmentStatus.js

/**
 * Updates the status of an appointment.
 * @param {string} serviceOrderNumber - The Service Order Number.
 * @param {string} appointmentNumber - The Appointment Number.
 * @param {string} newStatus - The new status to set.
 * @param {string} updatedBy - The username of the person updating the status.
 * @returns {Promise<Object>} - The response data from the API.
 */
export async function updateAppointmentStatus(serviceOrderNumber, appointmentNumber, newStatus, updatedBy) {
  const updateAppointmentEndpoint =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment';

  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Replace with your actual API key or use environment variables

  try {
    const response = await fetch(updateAppointmentEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      body: JSON.stringify({
        ServiceOrderID: serviceOrderNumber,
        AppointmentNumber: appointmentNumber,
        Status: newStatus,
        UpdatedBy: updatedBy || 'Unknown User' // Add the updatedBy parameter
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      console.error('Failed to update appointment status:', response.status, errorBody);
      throw new Error('Failed to update appointment status');
    }

    const data = await response.json();
    //console.log('Update Appointment Status Response:', data);

    // Directly use the 'data' property without parsing 'body'
    if (data.data) {
      return data.data;
    } else {
      console.error('Missing "data" property in response:', data);
      throw new Error('Invalid response structure from update appointment status API');
    }
  } catch (error) {
    console.error('Error updating appointment status:', error);
    throw error;
  }
}