// src/components/AppointmentScreen.jsx

import './css/appointments.css';

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { fetchAndMatchTechnicians } from '../utils/fetchTechnicians';
import { fetchAppointments } from '../utils/fetchAppointments';
import { cancelAppointment } from '../utils/cancelAppointment';
import { updateAppointmentStatus } from '../utils/updateAppointmentStatus';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Confetti from 'react-confetti';
import UserContext from './context/UserContext';
import { fetchServiceOrderDetails } from '../utils/fetchServiceOrderDetails';
import { Form } from 'react-bootstrap';
import { sendEmail } from '../utils/email';
import { Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';
import { FaStickyNote, FaSpinner } from 'react-icons/fa';
import { fetchVendors } from '../utils/fetchVendors';

import ImageGallery from './ImageGallery'; // Import ImageGallery component
import DOMPurify from 'dompurify'; // Add this import at the top

export default function AppointmentScreen() {
  const { serviceOrderNumber } = useParams();
  const location = useLocation();
  const [serviceOrderDetails, setServiceOrderDetails] = useState(
    location.state?.serviceOrderDetails || {}
  );

  const [appointments, setAppointments] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [technicianSearchTerm, setTechnicianSearchTerm] = useState('');
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });
  const [modalStep, setModalStep] = useState(1); // Step 1: Select Technician, Step 2: Select Date
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti display
  const [techNumber, setTechNumber] = useState(1); // New state for Tech number

  const modalRef = useRef(null); // Create a ref for the modal
  const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });

  const { user } = useContext(UserContext); // Get current user from UserContext

  // State for hold functionality
  const [holdLoading, setHoldLoading] = useState(false);
  const [holdError, setHoldError] = useState(null);

  // State for technician change modal
  const [showTechChangeModal, setShowTechChangeModal] = useState(false);
  const [appointmentToChangeTech, setAppointmentToChangeTech] = useState(null);

  // State for appointment date change modal
  const [showDateChangeModal, setShowDateChangeModal] = useState(false);
  const [appointmentToChangeDate, setAppointmentToChangeDate] = useState(null);
  const [newAppointmentDate, setNewAppointmentDate] = useState(null);

  // State for dispatch notes modal
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [appointmentForNotes, setAppointmentForNotes] = useState(null);
  const [dispatchNotes, setDispatchNotes] = useState('');

  // **Configuration: API Key and Endpoint URLs**
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Shared API Key for endpoints
  const CREATE_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_visit'; 
  const CREATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_insert_appointment';
  const UPDATE_TARGET_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';
  const UPDATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment_details';

  // Add state to store the appointment duration
  const [appointmentDuration, setAppointmentDuration] = useState(1);

  // Add these state variables at the top of your component
  const [showDurationModal, setShowDurationModal] = useState(false);
  const [selectedAppointmentForDuration, setSelectedAppointmentForDuration] = useState(null);
  const [newDuration, setNewDuration] = useState(1);

  // Add this new state variable
  const [isSubcontractor, setIsSubcontractor] = useState(false);

  const [vendorsLoading, setVendorsLoading] = useState(false);

  // State for subcontractor modal
  const [showSubcontractorModal, setShowSubcontractorModal] = useState(false);
  const [selectedAppointmentForSubcontractor, setSelectedAppointmentForSubcontractor] = useState(null);

  // State for email sending status
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailError, setEmailError] = useState(null);

  // State for technicians loading
  const [techniciansLoading, setTechniciansLoading] = useState(false);

  // ---- NEW: For Time Off Data ----
  const TIMEOFF_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_timeoff';
  const [timeOffData, setTimeOffData] = useState([]); 
  // --------------------------------

  // Add state for customer notes
  const [customerNotes, setCustomerNotes] = useState('');
  const [customerNotesLoading, setCustomerNotesLoading] = useState(false);
  const [customerNotesError, setCustomerNotesError] = useState(null);

  // Function to decode HTML entities
  const decodeHtmlEntities = (str) => {
    if (!str) return str;
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  };

  useEffect(() => {
    if (modalRef.current) {
      setModalDimensions({
        width: modalRef.current.offsetWidth,
        height: modalRef.current.offsetHeight,
      });
    }
  }, [showModal, showTechChangeModal, modalStep]); // Update dimensions when modal opens or step changes

  // Fetch appointments for the serviceOrderNumber
  useEffect(() => {
    const getAppointments = async () => {
      setAppointmentsLoading(true);
      setError(null);

      try {
        const fetchedAppointments = await fetchAppointments(serviceOrderNumber);
        setAppointments(
          fetchedAppointments.map((appointment) => ({
            ...appointment,
            AppointmentDate: appointment.AppointmentDate || 'N/A', // Ensure AppointmentDate is set
          }))
        );
        setAppointmentsLoading(false);
      } catch (err) {
        console.error('Error fetching appointments:', err);
        setError('Failed to load appointments. Please try again later.');
        setAppointmentsLoading(false);
      }
    };

    getAppointments();
  }, [serviceOrderNumber]);

  /**
   * Handles the action of adding a new appointment.
   * Fetches available technicians and displays the selection modal.
   */
  const handleAddAppointment = () => {
    setModalStep(1); // Reset to the first step when starting a new appointment
    setShowModal(true);
  };

  /**
   * Sets the selected technician based on user selection.
   * @param {Object} technician - The technician object selected by the user.
   */
  const handleSelectTechnician = (technician) => {
    setSelectedTechnician(technician);
  };

  /**
   * Extracts the location ID from the customer name.
   * Assumes the location ID is the last 4 digits.
   * @returns {string|null} - The extracted location ID or null if not found.
   */
  const extractLocationId = () => {
    const customerName = serviceOrderDetails?.customerName || '';
    const locationIdMatch = customerName.match(/T(\d{4})$/);
    if (locationIdMatch && locationIdMatch[1]) {
      return locationIdMatch[1];
    }
    // Fallback: last 4 characters
    const lastFour = customerName.slice(-4);
    return /^\d{4}$/.test(lastFour) ? lastFour : null;
  };

  /**
   * Calculates the estimated time of arrival, which is always one hour from the current time.
   * @returns {string} - The estimated time of arrival in ISO format.
   */
  const getEstimatedTimeArrival = () => {
    const now = new Date();
    const estimatedTime = new Date(now.getTime() + 60 * 60 * 1000); // Add 1 hour
    return estimatedTime.toISOString();
  };

  /**
   * Fetches Visit ID from the Create Visit API and returns it.
   * Adds logging for the request and response.
   * @returns {string|null} - The fetched VisitID or null if not applicable.
   */
  const fetchVisitID = async () => {
    // Check if customer name includes "Target" and customer order is valid
    const customerName = serviceOrderDetails?.customerName || '';
    const customerOrder = serviceOrderDetails?.customerOrder || '';

    const isTargetCustomer = customerName.toLowerCase().includes('target');
    const isValidCustomerOrder =
      customerOrder &&
      customerOrder.trim() !== '' &&
      customerOrder.trim().toLowerCase() !== 'n/a';

    if (isTargetCustomer && isValidCustomerOrder) {
      const location_id = extractLocationId();
      if (!location_id) {
        console.error('Location ID could not be extracted from customer name:', customerName);
        setError('Invalid customer name format. Cannot extract location ID.');
        return null;
      }

      // **Send the request payload directly as expected by the API**
      const requestBody = {
        related_tranid: customerOrder.trim(),
        technician_info: selectedTechnician?.employeeName || 'CCS Technician',
        estimated_time_arrival: getEstimatedTimeArrival(),
        location_id: location_id,
      };

      /*console.log('Sending request to CREATE_VISIT_ENDPOINT:', {
        url: CREATE_VISIT_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: requestBody,
      });*/

      try {
        const response = await fetch(CREATE_VISIT_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(requestBody),
        });

        console.log('Received response from CREATE_VISIT_ENDPOINT:', {
          status: response.status,
          ok: response.ok,
        });

        const responseText = await response.text();
        //console.log('Response body:', responseText);

        if (!response.ok) {
          console.error('Failed to fetch VisitID:', response.status, responseText);
          try {
            const errorData = JSON.parse(responseText);
            setError(`Failed to fetch VisitID: ${errorData.error || 'Unknown error'}`);
          } catch (parseError) {
            setError('Failed to fetch VisitID. Appointment created without VisitID.');
          }
          return null;
        }

        // Directly parse the responseText as JSON
        const data = JSON.parse(responseText);
        //console.log('Parsed response data:', data);

        // Use the correct key 'visit_id' from the response
        if (data && typeof data.visit_id === 'number') {
          //console.log('Fetched VisitID:', data.visit_id);
          return data.visit_id;
        } else {
          console.error('VisitID not found or invalid in response:', data);
          setError('VisitID not found or invalid in response. Appointment created without VisitID.');
          return null;
        }
      } catch (error) {
        console.error('Error fetching VisitID:', error);
        setError('Failed to fetch VisitID. Appointment created without VisitID.');
        return null;
      }
    }

    return null;
  };

  /**
   * Creates a new appointment by calling the backend API.
   * If the customer is a "Target" customer, it first creates a visit and includes the VisitID.
   * Otherwise, it creates an appointment without a VisitID.
   */
  const handleCreateAppointment = async () => {
    if (!selectedAppointmentDate) {
      alert('Please select an appointment date.');
      return;
    }

    if (!selectedTechnician) {
      console.error('No technician or vendor selected');
      return;
    }

    // Show confetti immediately when the button is clicked
    setShowConfetti(true);

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const description = serviceOrderDetails?.description || serviceOrderDetails?.details || '';

      // Sanitize the customerName and description to prevent SQL injection
      const sanitizeInput = (input) => {
        return input.replace(/'/g, "''"); // Escape single quotes by doubling them
      };

      const sanitizedCustName = sanitizeInput(customerName);
      const sanitizedAppDescription = sanitizeInput(description);

      const isTargetCustomer = customerName.toLowerCase().includes('target');

      let visitID = null;

      if (isTargetCustomer) {
        visitID = await fetchVisitID();
        if (visitID === null) {
          setError('Cannot create appointment without a valid VisitID.');
          setLoading(false);
          return;
        }
      }

      // Determine TechnicianEmail and TechnicianName based on isSubcontractor
      let TechnicianEmail, TechnicianName;

      if (isSubcontractor) {
        TechnicianEmail = user.email; // Use the user's email
        TechnicianName = user.display_name || user.name || 'Technician'; // Use user's display name
      } else {
        TechnicianEmail = selectedTechnician.email || 'acumatica@collins-cs.com';
        TechnicianName =
          selectedTechnician.email === 'acumatica@collins-cs.com'
            ? 'Unassigned'
            : selectedTechnician.employeeName || 'Technician';
      }

      // Build the request body with the new CustID field
      const requestBody = {
        ServiceOrderID: serviceOrderNumber,
        TechnicianEmail: TechnicianEmail,
        AppointmentDate: selectedAppointmentDate,
        Tech: techNumber,
        Duration: appointmentDuration,
        BranchLocation: serviceOrderDetails.department || 'N/A',
        CustAddress: serviceOrderDetails.address || 'N/A',
        CustName: sanitizedCustName,
        AppDescription: sanitizedAppDescription,
        CustID: (serviceOrderDetails?.CustID || '').trim(), // Trimmed CustID
        ...(isTargetCustomer && { VisitID: visitID }),
        ...(isSubcontractor && {
          Subbed: 1,
          SubName: selectedTechnician.vendorName,
          SubEmail: selectedTechnician.vendorEmail,
          SubPhone: selectedTechnician.vendorPhone, // Added SubPhone here
        }),
        // NEW: Add CreatedBy field with the current user's username or email
        CreatedBy: user?.username || user?.email || 'Unknown User',
      };

      // Log the payload and the CustID value to verify it is passed correctly
      //console.log('Sending request to CREATE_APPOINTMENT_ENDPOINT with payload:', requestBody);
      //console.log('CustID being sent:', requestBody.CustID);

      const response = await fetch(CREATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(requestBody),
      });

      console.log('Received response from CREATE_APPOINTMENT_ENDPOINT:', {
        status: response.status,
        ok: response.ok,
      });

      const responseText = await response.text();
      //console.log('Response body:', responseText);

      if (!response.ok) {
        console.error('Failed to create appointment:', response.status, responseText);
        try {
          const errorData = JSON.parse(responseText);
          setError(`Failed to create appointment: ${errorData.error || 'Unknown error'}`);
        } catch (parseError) {
          setError('Failed to create appointment.');
        }
        throw new Error('Failed to create appointment');
      }

      const data = JSON.parse(responseText);
      //console.log('Create Appointment Response:', data);

      let parsedBody;
      try {
        parsedBody = JSON.parse(data.body);
        //console.log('Parsed appointment data:', parsedBody);
      } catch (parseError) {
        console.error('Error parsing response body:', parseError);
        throw new Error('Invalid response format from Create Appointment API');
      }

      if (parsedBody && parsedBody.data) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody.data;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName: TechnicianName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber,
            Duration: appointmentDuration,
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0,
            ...(isSubcontractor && {
              Subbed: 1,
              SubName: selectedTechnician.vendorName,
              SubEmail: selectedTechnician.vendorEmail,
              SubPhone: selectedTechnician.vendorPhone,
            }),
          },
        ]);
      } else if (parsedBody && parsedBody.AppointmentNumber) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName: TechnicianName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber,
            Duration: appointmentDuration,
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0,
            ...(isSubcontractor && {
              Subbed: 1,
              SubName: selectedTechnician.vendorName,
              SubEmail: selectedTechnician.vendorEmail,
              SubPhone: selectedTechnician.vendorPhone,
            }),
          },
        ]);
      } else {
        console.error('Missing "data" property in response:', parsedBody);
        throw new Error('Invalid response structure from Create Appointment API');
      }

      handleModalClose();
      setSuccess('Appointment created successfully.');

      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error creating appointment:', error);
      setError(error.message || 'Failed to create appointment');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowConfetti(false);
        handleModalClose();
      }, 5000);
    }
  };

  /**
   * Initiates the cancellation process for a selected appointment.
   * @param {Object} appointment - The appointment object to be canceled.
   */
  const handleCancelAppointment = (appointment) => {
    setAppointmentToCancel(appointment);
    setShowCancelModal(true);
  };

  /**
   * Confirms and processes the cancellation of an appointment.
   */
  const confirmCancelAppointment = async () => {
    if (!appointmentToCancel) return;

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const isTargetCustomer = customerName.toLowerCase().includes('target');
      const visitID = appointmentToCancel.VisitID; // Assuming VisitID is stored in the appointment object
      
      // Get current user's username
      const updatedBy = user?.username || user?.email || 'Unknown User';

      await cancelAppointment(serviceOrderNumber, appointmentToCancel.AppointmentNumber, visitID, isTargetCustomer, updatedBy);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToCancel.AppointmentNumber
            ? { 
                ...appt, 
                Status: 'Canceled', 
                UpdatedAt: new Date().toISOString(),
                UpdatedBy: updatedBy // Update the UpdatedBy field locally too
              }
            : appt
        )
      );

      setShowCancelModal(false);
      setAppointmentToCancel(null);
      setSuccess('Appointment canceled successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error canceling appointment:', error);
      setError(error.message || 'Failed to cancel appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the dispatching of an appointment by updating its status to "Dispatched".
   * @param {Object} appointment - The appointment object to be dispatched.
   */
  const handleDispatchAppointment = async (appointment) => {
    try {
      setLoading(true);
      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, 'Dispatched');

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, Status: 'Dispatched', UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setSuccess('Appointment dispatched successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error dispatching appointment:', error);
      setError(error.message || 'Failed to dispatch appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Formats a date string into MM/DD/YYYY format using UTC to prevent time zone issues.
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date string.
   */
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const year = dateObj.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  /**
   * Fetch technicians or vendors depending on the isSubcontractor flag.
   * Also fetch Time Off data for technicians.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSubcontractor) {
          //console.log('Fetching vendors...');
          setVendorsLoading(true);
          const fetchedVendors = await fetchVendors();
          //console.log('Fetched vendors:', fetchedVendors);
          // Decode the vendor names
          const decodedVendors = fetchedVendors.map((vendor) => ({
            ...vendor,
            vendorName: decodeHtmlEntities(vendor.vendorName),
          }));
          setVendors(decodedVendors);
          setVendorsLoading(false);
        } else {
          //console.log('Fetching technicians...');
          setTechniciansLoading(true);
          const fetchedTechnicians = await fetchAndMatchTechnicians();
          //console.log('Fetched technicians:', fetchedTechnicians);
          setTechnicians(fetchedTechnicians);
          setTechniciansLoading(false);
        }

        // ---- FETCH TIME OFF DATA FOR TECHS ----
        if (!isSubcontractor) {
          //console.log('Fetching technician time off data...');
          const resp = await fetch(TIMEOFF_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY,
            },
            body: JSON.stringify({}), // empty => get all current/upcoming
          });

          if (!resp.ok) {
            const errText = await resp.text();
            throw new Error(`Error fetching time off: ${resp.status} - ${errText}`);
          }

          const timeOffJson = await resp.json();
          setTimeOffData(timeOffJson);
          //console.log('Fetched time off data:', timeOffJson);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
        setTechniciansLoading(false);
        setVendorsLoading(false);
      }
    };

    fetchData();
  }, [isSubcontractor]);

  useEffect(() => {
    const filterList = () => {
      if (isSubcontractor) {
        if (!Array.isArray(vendors)) return [];
        return vendors.filter((vendor) =>
          Object.values(vendor).some((value) =>
            value.toString().toLowerCase().includes(technicianSearchTerm.toLowerCase())
          )
        );
      } else {
        if (!Array.isArray(technicians)) return [];
        return technicians.filter((tech) =>
          Object.values(tech).some((value) =>
            value.toString().toLowerCase().includes(technicianSearchTerm.toLowerCase())
          )
        );
      }
    };

    setFilteredTechnicians(filterList());
  }, [technicians, vendors, technicianSearchTerm, isSubcontractor]);

  const handleTechnicianSearchChange = (e) => {
    setTechnicianSearchTerm(e.target.value);
  };

  const handleNextStep = () => {
    if (modalStep === 1) {
      setModalStep(2); // Move to the next step regardless of technician selection
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalStep(1); // Reset to first step when modal is closed
    setSelectedTechnician(null);
    setSelectedAppointmentDate(null);
  };

  const getButtonLabel = (status) => {
    switch (status.toLowerCase()) {
      case 'assigned':
        return 'Dispatch';
      case 'dispatched':
        return 'Depart';
      case 'depart':
        return 'Arrive';
      case 'arrive':
        return 'Complete';
      case 'complete':
        return 'Completed';
      case 'canceled':
        return 'Canceled';
      default:
        return '';
    }
  };

  /**
   * Handles the status change of an appointment.
   * Sends an email when an appointment is dispatched or completed.
   * @param {Object} appointment - The appointment object.
   */
  const handleStatusChange = async (appointment) => {
    try {
      setLoading(true);
      let newStatus;
      switch (appointment.Status.toLowerCase()) {
        case 'assigned':
          newStatus = 'Dispatched';
          break;
        case 'dispatched':
          newStatus = 'Depart';
          break;
        case 'depart':
          newStatus = 'Arrive';
          break;
        case 'arrive':
          newStatus = 'Complete';
          break;
        default:
          return;
      }

      // Get current user's username
      const updatedBy = user?.username || user?.email || 'Unknown User';

      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, newStatus, updatedBy);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { 
                ...appt, 
                Status: newStatus, 
                UpdatedAt: new Date().toISOString(),
                UpdatedBy: updatedBy // Update the UpdatedBy field locally too
              }
            : appt
        )
      );

      setSuccess(`Appointment status updated to ${newStatus}.`);

      // **Send an email when the appointment is dispatched**
      if (newStatus === 'Dispatched') {
        // Log key variables to debug
        //console.log('[handleStatusChange] newStatus:', newStatus);
        //console.log('[handleStatusChange] appointment:', appointment);
        //console.log('[handleStatusChange] serviceOrderNumber:', serviceOrderNumber);
        //console.log('[handleStatusChange] serviceOrderDetails:', serviceOrderDetails);

        // Get the concatenated service order number and appointment number
        const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;
        //console.log('[handleStatusChange] serviceOrderAppointmentID:', serviceOrderAppointmentID);

        // Technician's email (dispatcher)
        const technicianEmail = appointment.TechnicianEmail;
        //console.log('[handleStatusChange] technicianEmail:', technicianEmail);

        // Subcontractor's email
        const subcontractorEmail = appointment.SubEmail;
        //console.log('[handleStatusChange] subcontractorEmail:', subcontractorEmail);

        // Email recipients
        let toAddresses;
        if (appointment.Subbed === 1) {
          // Send email to both technician (dispatcher) and subcontractor
          toAddresses = [technicianEmail, subcontractorEmail].join(',');
        } else {
          // Send email to technician only
          toAddresses = technicianEmail;
        }
        //console.log('[handleStatusChange] toAddresses:', toAddresses);

        // Email components
        const customerName = serviceOrderDetails?.customerName || 'N/A';
        const description = serviceOrderDetails?.details || serviceOrderDetails?.description || 'N/A';
        const address = serviceOrderDetails?.address || 'N/A';
        const customerOrderNumber = serviceOrderDetails?.customerOrder || ''; 
        //console.log('[handleStatusChange] customerName:', customerName);
        //console.log('[handleStatusChange] description:', description);
        //console.log('[handleStatusChange] address:', address);
        //console.log('[handleStatusChange] customerOrderNumber:', customerOrderNumber);

        // Define the Reply-To address
        const replyTo = 'dispatchgroup@collins-cs.com';

        // Define the email subject
        const emailSubject = `${serviceOrderAppointmentID} has been dispatched to you.`;

        // Determine if the appointment is with a subcontractor and if customer is Target
        const isSubcontractorAppointment = appointment.Subbed === 1;
        const isTargetCustomer = customerName.toLowerCase().includes('target');
        //console.log('[handleStatusChange] isSubcontractorAppointment:', isSubcontractorAppointment);
        //console.log('[handleStatusChange] isTargetCustomer:', isTargetCustomer);

        // Construct the email body
        let emailBody = '';

        if (isSubcontractorAppointment) {
          if (isTargetCustomer && customerOrderNumber) {
            // For Target subcontractor appointments
            emailBody =
              `${serviceOrderAppointmentID} has been dispatched to you.\n` +
              `Please use the following link to access your appointment:\n\n` +
              `https://servicetech.target.com/search#?company_id=0000023700&wonum=${customerOrderNumber}\n\n` +
              `Customer: ${customerName}\nDescription: ${description}`;
          } else {
            // For non-Target subcontractor appointments
            emailBody = `Appointment ${serviceOrderAppointmentID} has been dispatched to you.\n\n` +
                        `Customer: ${customerName}\nDescription: ${description}`;
          }
        } else {
          // For internal technicians
          emailBody =
            `${serviceOrderAppointmentID} has been dispatched to you.\n` +
            `Please access CONEXCS to view your appointment.\n\nCustomer: ${customerName}\n` +
            `Description: ${description}`;
        }

        // Include the address if available
        if (address && address !== 'N/A') {
          emailBody += `\nAddress: ${address}`;
        }

        // Send the email
        await sendEmail({
          toAddress: toAddresses,
          cc: '',
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // **Send an email when the appointment is completed**
      if (newStatus === 'Complete') {
        // Get the concatenated service order number and appointment number
        const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;

        // Email recipient
        const recipientEmail = 'dispatchgroup@collins-cs.com';

        // Technician's name
        const technicianName = appointment.TechnicianName || 'Technician';

        // Email components
        const customerName = serviceOrderDetails?.customerName || 'N/A';
        const description = serviceOrderDetails?.details || 'N/A';
        const address = serviceOrderDetails?.address || 'N/A';

        // Define the Reply-To address
        const replyTo = 'dispatchgroup@collins-cs.com';

        // Define the email subject with technician's name
        const emailSubject = `${serviceOrderAppointmentID} has been completed by ${technicianName}.`;

        // Construct the email body with technician's name
        let emailBody = `${serviceOrderAppointmentID} has been completed by ${technicianName}.\n\nCustomer: ${customerName}\nDescription: ${description}`;

        // Include the address if available
        if (address && address !== 'N/A') {
          emailBody += `\nAddress: ${address}`;
        }

        // Send the email
        await sendEmail({
          toAddress: recipientEmail,
          cc: '',
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment status:', error);
      setError(error.message || 'Failed to update appointment status');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function to handle toggling the hold status of an appointment.
   * @param {Object} appointment - The appointment object.
   */
  const handleToggleHold = async (appointment) => {
    if (holdLoading) return; // Prevent multiple clicks

    setHoldLoading(true);
    setHoldError(null);

    const isOnHold = appointment.OnHold === 1;
    const newHoldStatus = isOnHold ? 0 : 1;
    const displayName = user?.display_name || 'Unknown User'; // Use display_name with fallback
    const onHoldNotes = isOnHold ? '' : `${displayName} has placed this appointment on hold.`;

    const payload = {
      ServiceOrderID: appointment.ServiceOrderID || serviceOrderNumber,
      AppointmentNumber: appointment.AppointmentNumber,
      OnHold: newHoldStatus,
      OnHoldNotes: onHoldNotes,
    };

    try {
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_appointment_onhold',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, OnHold: newHoldStatus, OnHoldNotes: onHoldNotes }
            : appt
        )
      );

      setSuccess(
        `Appointment #${appointment.AppointmentNumber} is now ${
          newHoldStatus === 1 ? 'On Hold' : 'Off Hold'
        }.`
      );

      // **Send an email when the appointment hold status changes**
      const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;
      const emailTo = appointment.TechnicianEmail;
      const customerName = serviceOrderDetails?.customerName || 'N/A';
      const description = serviceOrderDetails?.details || 'N/A';

      // Define the CC and Reply-To addresses
      let cc = 'dispatchgroup@collins-cs.com';
      cc += ', glen.hollingsworth@collins-cs.com'; // If you need multiple CC
      const replyTo = 'dispatchgroup@collins-cs.com';

      if (newHoldStatus === 1) {
        // **Email when put on hold**
        const emailSubject = `${serviceOrderAppointmentID} has been put on hold.`;
        const emailBody = `Customer: ${customerName}
Description: ${description}

${serviceOrderAppointmentID} has been put on hold with the following note:

${onHoldNotes}`;

        await sendEmail({
          toAddress: emailTo,
          cc: cc,
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      } else {
        // **Email when taken off hold**
        const emailSubject = `${serviceOrderAppointmentID} has been taken off hold.`;
        const emailBody = `Customer: ${customerName}
Description: ${description}

${serviceOrderAppointmentID} has been taken off hold.`;

        await sendEmail({
          toAddress: emailTo,
          cc: cc,
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating hold status:', error);
      setHoldError('Failed to update hold status. Please try again.');
    } finally {
      setHoldLoading(false);
    }
  };

  // Fetch service order details if not provided via location.state
  useEffect(() => {
    if (!serviceOrderDetails || Object.keys(serviceOrderDetails).length === 0) {
      const getServiceOrderDetails = async () => {
        try {
          const details = await fetchServiceOrderDetails(serviceOrderNumber);
          setServiceOrderDetails(details);
        } catch (error) {
          console.error('Error fetching service order details:', error);
          setError('Failed to load service order details. Please try again later.');
        }
      };

      getServiceOrderDetails();
    }
  }, [serviceOrderNumber, serviceOrderDetails]);

  /**
   * Handles the click on the technician's name.
   * Opens the technician change modal.
   * @param {Object} appointment - The appointment object.
   */
  const handleTechnicianNameClick = (appointment) => {
    setAppointmentToChangeTech(appointment);
    setSelectedTechnician(null); // Reset selected technician
    setShowTechChangeModal(true);
  };

  /**
   * Handles updating the technician for an appointment.
   * Calls the API to update the technician email.
   */
  const handleUpdateTechnician = async () => {
    if (!selectedTechnician || !appointmentToChangeTech) return;

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum = serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentToChangeTech.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        TechnicianEmail: selectedTechnician.email,
      };

      //console.log('Updating technician with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToChangeTech.AppointmentNumber
            ? {
                ...appt,
                TechnicianEmail: selectedTechnician.email,
                TechnicianName:
                  selectedTechnician.email === 'acumatica@collins-cs.com'
                    ? 'Unassigned'
                    : selectedTechnician.employeeName,
              }
            : appt
        )
      );

      setSuccess(
        `Technician for Appointment #${appointmentToChangeTech.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowTechChangeModal(false);
      setAppointmentToChangeTech(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating technician:', error);
      setError('Failed to update technician. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the click on the appointment date.
   * Opens the date change modal.
   * @param {Object} appointment - The appointment object.
   */
  const handleAppointmentDateClick = (appointment) => {
    setAppointmentToChangeDate(appointment);
    setNewAppointmentDate(appointment.AppointmentDate); // Initialize with current date
    setShowDateChangeModal(true);
  };

  /**
   * Handles updating the appointment date for an appointment.
   * Calls the API to update the AppointmentDate field.
   */
  const handleUpdateAppointmentDate = async () => {
    if (!newAppointmentDate || !appointmentToChangeDate) return;

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum = serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentToChangeDate.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        AppointmentDate: newAppointmentDate,
      };

      //console.log('Updating appointment date with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToChangeDate.AppointmentNumber
            ? {
                ...appt,
                AppointmentDate: newAppointmentDate,
              }
            : appt
        )
      );

      setSuccess(
        `Appointment date for Appointment #${appointmentToChangeDate.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowDateChangeModal(false);
      setAppointmentToChangeDate(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment date:', error);
      setError('Failed to update appointment date. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders a visual representation of the blocks (duration) and makes them clickable.
   * @param {object} appointment - The appointment object.
   * @returns {JSX.Element} - The visual representation of the blocks.
   */
  const renderBlocks = (appointment) => {
    const duration = appointment.Duration || 1;
    const maxBlocks = 4;

    // Styles for the pips
    const pipStyle = {
      fontSize: '24px',
      marginRight: '2px',
      cursor: 'pointer',
    };

    let blocks = [];
    for (let i = 1; i <= maxBlocks; i++) {
      blocks.push(
        <span
          key={i}
          style={{
            ...pipStyle,
            color: i <= duration ? 'red' : 'gray',
          }}
          onClick={() => handlePipClick(appointment)}
        >
          ●
        </span>
      );
    }

    return <div style={{ whiteSpace: 'nowrap' }}>{blocks}</div>;
  };

  /**
   * Handles the click on the pips to change the duration.
   * @param {object} appointment - The appointment object.
   */
  const handlePipClick = (appointment) => {
    setSelectedAppointmentForDuration(appointment);
    setNewDuration(appointment.Duration || 1);
    setShowDurationModal(true);
  };

  /**
   * Handles updating the duration of an appointment.
   */
  const handleUpdateDuration = async () => {
    if (!selectedAppointmentForDuration) {
      return;
    }

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum =
        serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${selectedAppointmentForDuration.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        Duration: newDuration,
      };

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update appointment duration: ${errorText}`);
      }

      const responseData = await response.json();
      //console.log('Update Duration Response:', responseData);

      // Update the appointments state with the new duration
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointmentForDuration.AppointmentNumber
            ? {
                ...appt,
                Duration: newDuration,
              }
            : appt
        )
      );

      // Close the modal
      setShowDurationModal(false);
      setSelectedAppointmentForDuration(null);

      // Show success message
      setSuccess('Appointment duration updated successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment duration:', error);
      setError('Failed to update appointment duration. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the click on the notes icon.
   * Opens the notes modal with the current dispatch notes.
   * @param {Object} appointment - The appointment object.
   */
  const handleNotesIconClick = (appointment) => {
    setAppointmentForNotes(appointment);
    setDispatchNotes(appointment.DispatchNotes || ''); // Initialize with current notes
    setShowNotesModal(true);
  };

  /**
   * Handles updating the dispatch notes for an appointment.
   * Calls the API to update the DispatchNotes field.
   */
  const handleSaveDispatchNotes = async () => {
    if (appointmentForNotes === null) return;

    try {
      setLoading(true);

      // Construct the ServiceOrderAppointmentID
      const serviceOrderNum =
        serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentForNotes.AppointmentNumber}`;

      // Insert the user's display_name and a colon before the entered note:
      const noteToSave = `${user.display_name}: ${dispatchNotes}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        DispatchNotes: noteToSave,
      };

      //console.log('Updating dispatch notes with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentForNotes.AppointmentNumber
            ? {
                ...appt,
                DispatchNotes: noteToSave,
              }
            : appt
        )
      );

      setSuccess(
        `Dispatch notes for Appointment #${appointmentForNotes.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowNotesModal(false);
      setAppointmentForNotes(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating dispatch notes:', error);
      setError('Failed to update dispatch notes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders the list of technicians or vendors, including highlighting if a tech is currently off.
   */
  const renderTechnicianList = () => {
    const list = filteredTechnicians;

    // Determine the loading state based on isSubcontractor
    const isLoading = isSubcontractor ? vendorsLoading : techniciansLoading;

    // Display loading message if data is still being fetched
    if (isLoading) {
      return (
        <div>
          {isSubcontractor ? 'Vendors loading...' : 'Technicians loading...'}
        </div>
      );
    }

    // Display the list if data is loaded and list is not empty
    if (list.length > 0) {
      return (
        <ul className="awf-technician-list">
          {list.map((item, index) => {
            const isVendor = isSubcontractor;

            let key;
            if (isVendor) {
              key = item.id ? `vendor-${item.id}` : `vendor-index-${index}`;
            } else {
              key = item.user_id ? `tech-${item.user_id}` : `tech-index-${index}`;
            }

            const displayName = isVendor
              ? item.vendorName || 'N/A'
              : item.email === 'acumatica@collins-cs.com'
              ? 'Unassigned'
              : item.employeeName;

            // ---- Check if this tech is currently off ----
            let rowStyle = {};
            let returningText = '';
            if (!isSubcontractor) {
              const now = new Date();
              // Find all time-off records for this tech
              const userTimeOffs = timeOffData.filter(
                (to) => to.user_id === item.user_id
              );

              // We'll determine if they are currently off
              // and store the earliest end date that covers "now"
              let isCurrentlyOff = false;
              let earliestEndDate = null;

              userTimeOffs.forEach((to) => {
                const start = new Date(to.startdate);
                const end = new Date(to.enddate);
                if (start <= now && now <= end) {
                  isCurrentlyOff = true;
                  if (!earliestEndDate || end < earliestEndDate) {
                    earliestEndDate = end;
                  }
                }
              });

              if (isCurrentlyOff && earliestEndDate) {
                rowStyle = { backgroundColor: '#fdcccc' };

                // Add "Returning mm/dd/yyyy" text
                const returnDate = new Date(earliestEndDate);
                returnDate.setDate(returnDate.getDate() + 1); // The day after end date
                const mm = String(returnDate.getUTCMonth() + 1).padStart(2, '0');
                const dd = String(returnDate.getUTCDate()).padStart(2, '0');
                const yyyy = returnDate.getUTCFullYear();
                returningText = ` (Returning ${mm}/${dd}/${yyyy})`;
              }
            }
            // --------------------------------------------

            const isSelected =
              selectedTechnician &&
              (isVendor
                ? selectedTechnician.id === item.id
                : selectedTechnician.user_id === item.user_id);

            return (
              <li
                key={key}
                className={`awf-technician-item ${isSelected ? 'selected' : ''}`}
                onClick={() => handleSelectTechnician(item)}
                style={rowStyle}
              >
                {displayName}
                {returningText && (
                  <span style={{ marginLeft: '8px', fontStyle: 'italic', color: '#555' }}>
                    {returningText}
                  </span>
                )}
                {isVendor && (
                  <div className="awf-vendor-contact">
                    <span>Email: {item.vendorEmail}</span><br />
                    <span>Phone: {item.vendorPhone}</span>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      );
    } else {
      // Display message if data is loaded but list is empty
      return (
        <div>
          No {isSubcontractor ? 'vendors' : 'technicians'} available
        </div>
      );
    }
  };

  /**
   * Handles the click on the subcontractor's name.
   * Opens the subcontractor information modal.
   * @param {Object} appointment - The appointment object.
   */
  const handleSubcontractorNameClick = (appointment) => {
    setSelectedAppointmentForSubcontractor(appointment);
    setShowSubcontractorModal(true);
  };

  /**
   * Retrieves the subcontractor's phone number based on the email.
   * @param {string} subEmail - The subcontractor's email.
   * @returns {string} - The subcontractor's phone number or 'N/A' if not found.
   */
  const getSubcontractorPhone = (subEmail) => {
    const subcontractor = vendors.find((vendor) => vendor.vendorEmail === subEmail);
    return subcontractor ? subcontractor.vendorPhone : 'N/A';
  };

  // Decode subcontractor and technician names in appointments
  useEffect(() => {
    const decodedAppointments = appointments.map((appointment) => ({
      ...appointment,
      SubName: appointment.SubName
        ? decodeHtmlEntities(appointment.SubName)
        : appointment.SubName,
      TechnicianName: appointment.TechnicianName
        ? decodeHtmlEntities(appointment.TechnicianName)
        : appointment.TechnicianName,
    }));
    setAppointments(decodedAppointments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once when the component mounts

  /**
   * Handles sending the dispatch email to the subcontractor.
   */
  const handleEmailSubcontractor = async () => {
    if (!selectedAppointmentForSubcontractor) return;

    try {
      setEmailSending(true);
      setEmailSuccess(null);
      setEmailError(null);

      // Get the subcontractor's email
      const subcontractorEmail = selectedAppointmentForSubcontractor.SubEmail;
      const technicianEmail = selectedAppointmentForSubcontractor.TechnicianEmail; // Dispatcher's email

      // Get the concatenated service order number and appointment number
      const serviceOrderAppointmentID = `${serviceOrderNumber}-${selectedAppointmentForSubcontractor.AppointmentNumber}`;

      // Email components
      const customerName = serviceOrderDetails?.customerName || 'N/A';
      const description = serviceOrderDetails?.details || serviceOrderDetails?.description || 'N/A';
      const address = serviceOrderDetails?.address || 'N/A';
      const customerOrderNumber = serviceOrderDetails?.customerOrder || '';

      // Define the Reply-To address
      const replyTo = 'dispatchgroup@collins-cs.com';

      // Define the email subject
      const emailSubject = `${serviceOrderAppointmentID} has been dispatched to you.`;

      // Determine if the customer is Target
      const isTargetCustomer = customerName.toLowerCase().includes('target');

      // Construct the email body
      let emailBody = '';

      if (isTargetCustomer && customerOrderNumber) {
        // For Target subcontractor appointments
        emailBody = `${serviceOrderAppointmentID} has been dispatched to you.\n` +
                    `Please use the following link to access your appointment:\n\n` +
                    `https://servicetech.target.com/search#?company_id=0000023700&wonum=${customerOrderNumber}\n\n` +
                    `Customer: ${customerName}\nDescription: ${description}`;
      } else {
        // For non-Target subcontractor appointments
        emailBody = `Appointment ${serviceOrderAppointmentID} has been dispatched to you.\n\n` +
                    `Customer: ${customerName}\nDescription: ${description}`;
      }

      // Include the address if available
      if (address && address !== 'N/A') {
        emailBody += `\nAddress: ${address}`;
      }

      // Send the email to the subcontractor
      await sendEmail({
        toAddress: subcontractorEmail,
        cc: technicianEmail,
        replyTo: replyTo,
        subject: emailSubject,
        body: emailBody,
      });

      setEmailSuccess('Email sent successfully.');
      // Clear success message after 5 seconds
      setTimeout(() => setEmailSuccess(null), 5000);
    } catch (error) {
      console.error('Error sending email to subcontractor:', error);
      setEmailError('Failed to send email to subcontractor.');
    } finally {
      setEmailSending(false);
    }
  };

  // Function to generate the ACU system URL
  const getAcuSystemUrl = () => {
    return `https://collinscommercialservices.acumatica.com/(W(1))/Main?CompanyID=Collins+Commercial+Services&ScreenId=FS300100&SrvOrdType=WO2+&RefNbr=${
      serviceOrderDetails.serviceOrderNumber || serviceOrderNumber || 'N/A'
    }`;
  };

  // Function to generate the PMWA system URL
  const getPmwaSystemUrl = () => {
    const customerOrderNumber = serviceOrderDetails.customerOrder || serviceOrderNumber || '';
    return `https://pmworkorderadmin.partnersonline.com/work-order-details#?work_order_number=${customerOrderNumber}&company_id=0000023700&current_count=0&`;
  };

  // Determine if the customer name contains "Target" (case-insensitive)
  const hasTarget =
    serviceOrderDetails.customerName && /target/i.test(serviceOrderDetails.customerName);

  useEffect(() => {
    //console.log('[AppointmentScreen] serviceOrderDetails:', serviceOrderDetails);
  }, [serviceOrderDetails]);

  // 1) Add this new helper function for "Undo" dispatch:
  const handleUndoDispatch = async (appointment) => {
    try {
      setLoading(true);
      const newStatus = 'Assigned';

      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, newStatus);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, Status: newStatus, UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setSuccess(`Appointment #${appointment.AppointmentNumber} is now set to Assigned.`);
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error reverting appointment to assigned:', error);
      setError(error.message || 'Failed to revert appointment to Assigned');
    } finally {
      setLoading(false);
    }
  };

  // Update the fetchCustomerNotes function to handle special characters properly 
  // while matching what works in AppointmentWorkFlow.jsx
  const fetchCustomerNotes = async () => {
    if (!serviceOrderDetails?.customerName) {
      console.warn('No customer name available. Cannot fetch customer notes.');
      setCustomerNotes('Customer information is still loading...');
      return;
    }

    setCustomerNotesLoading(true);
    setCustomerNotesError(null);

    try {
      // Extract just the company part (before the colon) for best results
      // This matches what seems to work on the server side
      let queryCustomerName = serviceOrderDetails.customerName;
      
      // If customer name has a colon, get just the first part
      if (queryCustomerName.includes(':')) {
        queryCustomerName = queryCustomerName.split(':')[0].trim();
      }
      
      // Remove any # symbols which break OData queries
      queryCustomerName = queryCustomerName.replace(/#/g, '');
      
      console.log('Querying for customer notes with name:', queryCustomerName);
      
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_customer_details',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify({
            customerName: queryCustomerName,
          }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Customer Details:', data);

      if (
        data.customerDetails &&
        Array.isArray(data.customerDetails) &&
        data.customerDetails.length > 0
      ) {
        const customerNote =
          data.customerDetails[0]?.note?.value || 'No customer notes available.';
        setCustomerNotes(customerNote);
      } else {
        console.warn('No customer details found in the response.');
        setCustomerNotes('No customer notes available.');
      }
    } catch (err) {
      console.error('Error fetching customer notes:', err);
      // Don't show error to user, just display a friendlier message
      setCustomerNotes('Customer notes are still loading. Please wait a moment or refresh the page.');
      // Still set the error state but don't display it prominently
      setCustomerNotesError('Notes loading in progress');
    } finally {
      setCustomerNotesLoading(false);
    }
  };

  // Modify the useEffect to handle potential race conditions
  useEffect(() => {
    if (serviceOrderDetails?.customerName) {
      // Add a small delay to allow other critical data to load first
      const timer = setTimeout(() => {
        fetchCustomerNotes();
      }, 500);
      
      return () => clearTimeout(timer);
    } else {
      // Set a default state when customer name isn't available yet
      setCustomerNotes('Customer information is loading...');
    }
  }, [serviceOrderDetails?.customerName]);

  // Add the renderCustomerNotes function that matches AppointmentWorkFlow.jsx
  const renderCustomerNotes = () => {
    return (
      <div className="awf-customerNotesSection">
        {customerNotesLoading ? (
          <div className="awf-loading">
            <Spinner animation="border" role="status" size="sm"></Spinner>
            <span> Loading customer notes...</span>
          </div>
        ) : customerNotesError === 'Notes loading in progress' ? (
          // Special case for when notes are still being loaded but we don't want to show an error
          <div className="card mb-3">
            <div className="card-header" style={{ backgroundColor: '#0066cc', color: 'white' }}>
              <strong>Permanent / Customer Notes</strong>
            </div>
            <div className="card-body">
              <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {customerNotes}
              </div>
            </div>
          </div>
        ) : customerNotesError ? (
          <div className="awf-error">{customerNotesError}</div>
        ) : (
          <div className="card mb-3">
            <div
              className="card-header"
              style={{ backgroundColor: '#0066cc', color: 'white' }}
            >
              <strong>Permanent / Customer Notes</strong>
            </div>
            <div className="card-body">
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(customerNotes),
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="appointment-screen-container">
      <div className="awf-content-spacing">
        <div className="awf-card">
          <div className="awf-cardHeader">
            <h2
              className="awf-cardTitle"
              style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
              onClick={() =>
                window.open(
                  getAcuSystemUrl(),
                  '_blank'
                )
              }
            >
              Appointments for Service Order {serviceOrderDetails.serviceOrderNumber || serviceOrderNumber || 'N/A'}
            </h2>
            <div className="service-order-details" style={{ marginLeft: '0', marginTop: '10px' }}>
              <p style={{ marginBottom: '0' }}>
                <strong>Customer:</strong> {serviceOrderDetails.customerName || 'N/A'}
              </p>
              <p style={{ marginBottom: '0' }}>
                <strong>Description:</strong> {serviceOrderDetails.details || 'N/A'}
              </p>
              <p style={{ marginBottom: '0' }}>
                <strong>Customer Order:</strong> {serviceOrderDetails.customerOrder || 'N/A'}
              </p>
              <p style={{ marginBottom: '0' }}>
                <strong>Department:</strong> {serviceOrderDetails.department || 'N/A'}
              </p>
              <p style={{ marginBottom: '0' }}>
                <strong>Address:</strong> {serviceOrderDetails.address || 'N/A'}
              </p>
              {/* Icon Buttons Aligned with Details */}
              <div
                className="icon-buttons"
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginTop: '10px',
                  marginLeft: '0',
                }}
              >
                {/* ACU Button */}
                <button
                  onClick={() => window.open(getAcuSystemUrl(), '_blank')}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    width: '32px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0',
                    cursor: 'pointer',
                  }}
                  aria-label="ACU System"
                >
                  <img
                    src="/acu.ico"
                    alt="ACU System"
                    style={{ width: '16px', height: '16px' }}
                  />
                </button>

                {/* PMWA Button (Only if Customer Name Contains "Target") */}
                {hasTarget && (
                  <button
                    onClick={() => window.open(getPmwaSystemUrl(), '_blank')}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      width: '32px',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0',
                      cursor: 'pointer',
                    }}
                    aria-label="PMWA System"
                  >
                    <img
                      src="/pmwa.ico"
                      alt="PMWA System"
                      style={{ width: '16px', height: '16px' }}
                    />
                  </button>
                )}
              </div>
            </div>
          </div>

          <nav className="breadcrumb" style={{ marginBottom: '20px', fontSize: '16px' }}>
            <Link to="/ServiceOrderLookup" className="breadcrumb-link" style={{ textDecoration: 'none', color: '#007bff' }}>
              Service Order Lookup
            </Link>
            <span className="breadcrumb-separator" style={{ margin: '0 8px' }}>/</span>
            <span className="breadcrumb-current" style={{ color: '#6c757d' }}>Appointments</span>
          </nav>

          <div className="awf-cardContent">
            {appointmentsLoading && (
              <div className="awf-loading">
                <Spinner animation="border" role="status" size="sm"></Spinner>
                <span> Loading appointments...</span>
              </div>
            )}
            {error && <div className="awf-error">{error}</div>}
            {success && <div className="awf-success">{success}</div>}
            {holdError && <div className="awf-error">{holdError}</div>}
            {!appointmentsLoading && !error && (
              <>
                {appointments.length > 0 ? (
                  <table
                    className="awf-table"
                    style={{
                      tableLayout: 'fixed',
                      width: '100%',
                      borderCollapse: 'collapse',
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '40px' }}>#</th>
                        <th style={{ width: '300px' }}>Technician</th>
                        <th style={{ width: '60px' }}>Tech #</th>
                        <th style={{ width: '110px' }}>Date</th>
                        <th style={{ width: '120px' }}>Status</th>
                        <th style={{ width: '50px' }}>Hold</th>
                        <th style={{ width: '80px' }}>Blocks</th>
                        <th style={{ width: '200px' }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointments.map((appointment, index) => (
                        <tr key={index}>
                          <td>{appointment.AppointmentNumber}</td>
                          <td>
                            {appointment.Subbed === 1 ? (
                              <span
                                onClick={() => handleSubcontractorNameClick(appointment)}
                                style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                              >
                                {decodeHtmlEntities(appointment.SubName) || 'N/A'}
                              </span>
                            ) : ['assigned', 'dispatched'].includes(appointment.Status.toLowerCase()) ? (
                              <span
                                onClick={() => handleTechnicianNameClick(appointment)}
                                style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                              >
                                {appointment.TechnicianEmail === 'acumatica@collins-cs.com'
                                  ? 'Unassigned'
                                  : decodeHtmlEntities(appointment.TechnicianName) || 'N/A'}
                              </span>
                            ) : (
                              appointment.TechnicianEmail === 'acumatica@collins-cs.com'
                                ? 'Unassigned'
                                : decodeHtmlEntities(appointment.TechnicianName) || 'N/A'
                            )}
                          </td>
                          <td>{appointment.Tech}</td>
                          <td>
                            <span
                              onClick={() => handleAppointmentDateClick(appointment)}
                              style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                            >
                              {appointment.AppointmentDate
                                ? formatDate(appointment.AppointmentDate)
                                : 'N/A'}
                            </span>
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip 
                                  id={`status-tooltip-${appointment.AppointmentNumber}`}
                                  className="awf-statusTooltip" // Add this class name
                                >
                                  <div style={{ textAlign: 'left', padding: '5px' }}>
                                    <div>
                                      <strong>Created By:</strong> {appointment.CreatedBy || ''}
                                    </div>
                                    <div>
                                      <strong>Updated By:</strong> {appointment.UpdatedBy || ''}
                                    </div>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span>{appointment.Status}</span>
                            </OverlayTrigger>
                          </td>
                          <td className="align-middle">
                            {appointment.OnHold === 1 || appointment.OnHold === true ? (
                              // Add the hover tooltip while preserving click functionality
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`hold-tooltip-${appointment.AppointmentNumber}`} className="awf-holdTooltip">
                                    <div className="awf-holdNotes">
                                      {appointment.OnHoldNotes || 'No reason provided'}
                                      {appointment.OnHoldDate && (
                                        <div className="awf-followUpDate">
                                          <strong>Follow-up Date:</strong> {new Date(appointment.OnHoldDate).toLocaleDateString()}
                                        </div>
                                      )}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span 
                                  className="text-danger" 
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleToggleHold(appointment)}
                                >
                                  Yes
                                </span>
                              </OverlayTrigger>
                            ) : (
                              <span 
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleToggleHold(appointment)}
                              >
                                No
                              </span>
                            )}
                          </td>
                          <td>{renderBlocks(appointment)}</td>
                          <td>
                            <button
                              className="awf-button awf-dispatchButton"
                              onClick={() => handleStatusChange(appointment)}
                              disabled={
                                appointment.Status.toLowerCase() === 'complete' ||
                                appointment.Status.toLowerCase() === 'canceled' ||
                                loading
                              }
                              style={{
                                width: '110px',
                                textAlign: 'center',
                                backgroundColor:
                                  appointment.Status.toLowerCase() === 'complete' ||
                                  appointment.Status.toLowerCase() === 'canceled'
                                    ? '#6c757d'
                                    : '#007bff',
                                cursor:
                                  appointment.Status.toLowerCase() === 'complete' ||
                                  appointment.Status.toLowerCase() === 'canceled'
                                    ? 'not-allowed'
                                    : 'pointer',
                                marginRight: '8px',
                              }}
                            >
                              {getButtonLabel(appointment.Status)}
                            </button>
                            <button
                              className="awf-button awf-cancelButton"
                              onClick={() => {
                                // If current status is "Dispatched", then revert to Assigned.
                                if (appointment.Status.toLowerCase() === 'dispatched') {
                                  handleUndoDispatch(appointment);
                                } else {
                                  // Otherwise, normal cancel
                                  handleCancelAppointment(appointment);
                                }
                              }}
                              disabled={
                                appointment.Status.toLowerCase() === 'canceled' ||
                                appointment.Status.toLowerCase() === 'complete' ||
                                loading
                              }
                              style={
                                appointment.Status.toLowerCase() === 'canceled' ||
                                appointment.Status.toLowerCase() === 'complete'
                                  ? { backgroundColor: '#6c757d', cursor: 'not-allowed' }
                                  : {}
                              }
                            >
                              {appointment.Status.toLowerCase() === 'dispatched' ? 'Undo' : 'Cancel'}
                            </button>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip>
                                  {appointment.DispatchNotes ? appointment.DispatchNotes : 'Add Dispatch Notes'}
                                </Tooltip>
                              }
                            >
                              <span
                                className="awf-notesIcon"
                                onClick={() => handleNotesIconClick(appointment)}
                                style={{ marginLeft: '8px', cursor: 'pointer' }}
                              >
                                <FaStickyNote
                                  color={appointment.DispatchNotes ? '#007bff' : '#6c757d'} 
                                  size={20}
                                />
                              </span>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="awf-noResults">No appointments found for this service order.</div>
                )}
                <button
                  className="awf-button awf-addButton"
                  onClick={handleAddAppointment}
                  disabled={loading || holdLoading}
                >
                  {loading || holdLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                      Loading...
                    </>
                  ) : (
                    'Add Appointment'
                  )}
                </button>

                {/* **Add ImageGallery Component Here** */}
                <div style={{ marginTop: '30px' }}>
                  <ImageGallery
                    custId={serviceOrderDetails.CustID}
                    serviceOrder={serviceOrderNumber}
                    allowAddImages={true}
                    // Add this prop to pass all appointment numbers
                    allAppointments={appointments.map(appt => appt.AppointmentNumber)}
                  />
                </div>

                

                {/* Add the customer notes section below the image gallery */}
                {appointments.length > 0 && (
                  <div className="mt-4">
                    <h3>Customer Information</h3>
                    {renderCustomerNotes()}
                  </div>
                )}

                {/* Modal for selecting technician or vendor */}
                <Modal
                  ref={modalRef}
                  show={showModal}
                  onHide={handleModalClose}
                  size="lg"
                  aria-labelledby="technician-selection-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="technician-selection-modal">
                      {modalStep === 1
                        ? `Select ${isSubcontractor ? 'Subcontractor' : 'Technician'}`
                        : 'Select Appointment Date'}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ position: 'relative' }}>
                    {showConfetti && (
                      <div
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          pointerEvents: 'none',
                          overflow: 'hidden',
                        }}
                      >
                        <Confetti
                          width={modalDimensions.width}
                          height={modalDimensions.height}
                          numberOfPieces={200}
                        />
                      </div>
                    )}
                    {modalStep === 1 ? (
                      <>
                        <input
                          type="text"
                          placeholder={`Search ${isSubcontractor ? 'subcontractors' : 'technicians'}...`}
                          value={technicianSearchTerm}
                          onChange={handleTechnicianSearchChange}
                          className="awf-searchInput"
                        />
                        {renderTechnicianList()}
                      </>
                    ) : (
                      <>
                        {/* Display the selected technician's name */}
                        <div style={{ marginBottom: '15px' }}>
                          <strong>Technician: </strong>
                          {selectedTechnician
                            ? isSubcontractor
                              ? selectedTechnician.vendorName
                              : selectedTechnician.employeeName
                            : 'None Selected'}
                        </div>
                        {/* Appointment Date Selection with label */}
                        <div style={{ marginTop: '15px' }}>
                          <label htmlFor="appointmentDate">
                            <strong>Appointment Date:</strong>
                          </label>
                          <input
                            id="appointmentDate"
                            type="date"
                            value={selectedAppointmentDate || ''}
                            onChange={(e) => setSelectedAppointmentDate(e.target.value)}
                            className="awf-dateInput"
                            style={{ marginLeft: '10px', padding: '5px' }}
                          />
                        </div>
                        {/* Duration Selection */}
                        <div style={{ marginTop: '15px' }}>
                          <label htmlFor="appointmentDuration">
                            <strong>Duration (Blocks):</strong>
                          </label>
                          <select
                            id="appointmentDuration"
                            value={appointmentDuration}
                            onChange={(e) => setAppointmentDuration(parseInt(e.target.value, 10))}
                            style={{ marginLeft: '10px', padding: '5px' }}
                          >
                            <option value={1}>1 Block</option>
                            <option value={2}>2 Blocks</option>
                            <option value={3}>3 Blocks</option>
                            <option value={4}>4 Blocks</option>
                          </select>
                        </div>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    {modalStep === 1 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Subcontractor checkbox */}
                        <Form.Check
                          type="checkbox"
                          id="subcontractorCheckbox"
                          label="Subcontractor"
                          checked={isSubcontractor}
                          onChange={(e) => setIsSubcontractor(e.target.checked)}
                        />
                        {/* Tech # input */}
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '15px',
                          }}
                        >
                          <label htmlFor="techNumber" style={{ marginRight: '5px' }}>
                            Tech #
                          </label>
                          <input
                            id="techNumber"
                            type="number"
                            value={techNumber}
                            onChange={(e) =>
                              setTechNumber(parseInt(e.target.value, 10))
                            }
                            className="awf-techInput"
                            style={{ width: '50px', textAlign: 'center' }}
                          />
                        </div>
                      </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        variant="secondary"
                        onClick={handleModalClose}
                        style={{ marginRight: '8px' }}
                      >
                        Cancel
                      </Button>
                      {modalStep === 1 ? (
                        <Button variant="primary" onClick={handleNextStep}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={handleCreateAppointment}
                          disabled={!selectedAppointmentDate || loading}
                        >
                          {loading ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{' '}
                              Creating...
                            </>
                          ) : (
                            'Create Appointment'
                          )}
                        </Button>
                      )}
                    </div>
                  </Modal.Footer>
                </Modal>

                {/* Cancel Confirmation Modal */}
                <Modal
                  show={showCancelModal}
                  onHide={() => setShowCancelModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Cancellation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to cancel Appointment #{appointmentToCancel?.AppointmentNumber}?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                      No
                    </Button>
                    <Button
                      variant="danger"
                      onClick={confirmCancelAppointment}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Canceling...
                        </>
                      ) : (
                        'Yes, Cancel Appointment'
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Modal for changing technician */}
                <Modal
                  show={showTechChangeModal}
                  onHide={() => setShowTechChangeModal(false)}
                  size="lg"
                  aria-labelledby="technician-change-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="technician-change-modal">
                      Change Technician for Appointment #{appointmentToChangeTech?.AppointmentNumber}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="text"
                      placeholder="Search technicians..."
                      value={technicianSearchTerm}
                      onChange={handleTechnicianSearchChange}
                      className="awf-searchInput"
                    />
                    {filteredTechnicians.length > 0 ? (
                      <ul className="awf-technician-list">
                        {filteredTechnicians.map((tech, index) => {
                          const displayName =
                            tech.email === 'acumatica@collins-cs.com'
                              ? 'Unassigned'
                              : tech.employeeName;
                          
                          let rowStyle = {};
                          let returningText = '';
                          
                          // Time off check (only if not subcontractor)
                          if (!isSubcontractor) {
                            const now = new Date();
                            const userTimeOffs = timeOffData.filter(
                              (to) => to.user_id === tech.user_id
                            );
                            let isCurrentlyOff = false;
                            let earliestEndDate = null;
              
                            userTimeOffs.forEach((to) => {
                              const start = new Date(to.startdate);
                              const end = new Date(to.enddate);
                              if (start <= now && now <= end) {
                                isCurrentlyOff = true;
                                if (!earliestEndDate || end < earliestEndDate) {
                                  earliestEndDate = end;
                                }
                              }
                            });
              
                            if (isCurrentlyOff && earliestEndDate) {
                              rowStyle = { backgroundColor: '#fdcccc' };
                              const returnDate = new Date(earliestEndDate);
                              returnDate.setDate(returnDate.getDate() + 1); // Next day of end date
                              const mm = String(returnDate.getUTCMonth() + 1).padStart(2, '0');
                              const dd = String(returnDate.getUTCDate()).padStart(2, '0');
                              const yyyy = returnDate.getUTCFullYear();
                              returningText = ` (Returning ${mm}/${dd}/${yyyy})`;
                            }
                          }
              
                          const isSelected =
                            selectedTechnician && selectedTechnician.user_id === tech.user_id;
              
                          return (
                            <li
                              key={tech.user_id || index}
                              className={`awf-technician-item ${isSelected ? 'selected' : ''}`}
                              onClick={() => handleSelectTechnician(tech)}
                              style={rowStyle}
                            >
                              {displayName}
                              {returningText && (
                                <span
                                  style={{
                                    marginLeft: '8px',
                                    fontStyle: 'italic',
                                    color: '#555',
                                  }}
                                >
                                  {returningText}
                                </span>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <div>No technicians available</div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTechChangeModal(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleUpdateTechnician}
                      disabled={!selectedTechnician || loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Updating...
                        </>
                      ) : (
                        'Save Changes'
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Modal for changing appointment date */}
                <Modal
                  show={showDateChangeModal}
                  onHide={() => setShowDateChangeModal(false)}
                  size="md"
                  aria-labelledby="appointment-date-change-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="appointment-date-change-modal">
                      Change Appointment Date for Appointment #{appointmentToChangeDate?.AppointmentNumber}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <input
                      type="date"
                      value={newAppointmentDate ? new Date(newAppointmentDate).toISOString().split('T')[0] : ''}
                      onChange={(e) => setNewAppointmentDate(e.target.value)}
                      className="awf-dateInput"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDateChangeModal(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleUpdateAppointmentDate}
                      disabled={!newAppointmentDate || loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Updating...
                        </>
                      ) : (
                        'Save Changes'
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Duration Change Modal */}
                <Modal show={showDurationModal} onHide={() => setShowDurationModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Change Appointment Duration</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formAppointmentDuration">
                        <Form.Label>Select New Duration (Blocks)</Form.Label>
                        <Form.Control
                          as="select"
                          value={newDuration}
                          onChange={(e) => setNewDuration(parseInt(e.target.value, 10))}
                        >
                          <option value={1}>1 Block</option>
                          <option value={2}>2 Blocks</option>
                          <option value={3}>3 Blocks</option>
                          <option value={4}>4 Blocks</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDurationModal(false)}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateDuration}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Dispatch Notes Modal */}
                <Modal
                  show={showNotesModal}
                  onHide={() => setShowNotesModal(false)}
                  size="md"
                  aria-labelledby="dispatch-notes-modal"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="dispatch-notes-modal">
                      Dispatch Notes for Appointment #{appointmentForNotes?.AppointmentNumber}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formDispatchNotes">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={5}
                          value={dispatchNotes}
                          onChange={(e) => setDispatchNotes(e.target.value)}
                          placeholder="Enter dispatch notes here..."
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowNotesModal(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleSaveDispatchNotes}
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Saving...
                        </>
                      ) : (
                        'Save Notes'
                      )}
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* Subcontractor Information Modal */}
                <Modal
                  show={showSubcontractorModal}
                  onHide={() => setShowSubcontractorModal(false)}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Subcontractor Information</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {selectedAppointmentForSubcontractor && (
                      <div>
                        <p>
                          <strong>Dispatcher:</strong> {user.email}
                        </p>
                        <p>
                          <strong>Subcontractor:</strong>{' '}
                          {decodeHtmlEntities(selectedAppointmentForSubcontractor.SubName)}
                        </p>
                        <p>
                          <strong>Email:</strong> {selectedAppointmentForSubcontractor.SubEmail}
                        </p>
                        <p>
                          <strong>Phone:</strong>{' '}
                          {selectedAppointmentForSubcontractor.SubPhone || 'N/A'}
                        </p>

                        {emailSuccess && (
                          <Alert variant="success" onClose={() => setEmailSuccess(null)} dismissible>
                            {emailSuccess}
                          </Alert>
                        )}
                        {emailError && (
                          <Alert variant="danger" onClose={() => setEmailError(null)} dismissible>
                            {emailError}
                          </Alert>
                        )}
                      </div>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={handleEmailSubcontractor}
                      disabled={emailSending}
                    >
                      {emailSending ? (
                        <>
                          <Spinner animation="border" size="sm" /> Sending...
                        </>
                      ) : (
                        'Email'
                      )}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowSubcontractorModal(false)}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
