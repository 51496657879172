// src/utils/fetchServiceOrderDetails.js

// API details
const API_ENDPOINT =
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so';
const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;

/**
 * Fetches service order details for a given service order number.
 * @param {string} serviceOrderNumber - The service order number to fetch details for.
 * @returns {Promise<Object>} - A promise that resolves to the mapped service order details.
 */
export async function fetchServiceOrderDetails(serviceOrderNumber) {
  try {
    // Remove 'FS' prefix if present
    const cleanedServiceOrderNumber = serviceOrderNumber.replace(/^FS/, '');
    //console.log('Cleaned Service Order Number:', cleanedServiceOrderNumber); // Log the cleaned number

    // Construct the request body as per the endpoint's requirements
    const innerBody = { serviceOrderID: cleanedServiceOrderNumber };
    const requestBody = {
      body: JSON.stringify(innerBody),
    };
    //console.log('Request body:', JSON.stringify(requestBody)); // Log the request body

    const response = await fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`API responded with status ${response.status}`);
    }

    const data = await response.json();
    //console.log('API Response:', data); // Debugging log

    // Parse the response body using the parser function
    const serviceOrdersArray = parseServiceOrders(data);

    //console.log('Parsed API Response Body:', serviceOrdersArray); // Debugging log

    if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
      // Map and return the first service order details
      const mappedOrder = mapServiceOrder(serviceOrdersArray[0]);
      return mappedOrder;
    } else {
      throw new Error('No service orders found for the given number.');
    }
  } catch (err) {
    console.error('Error fetching service order details:', err);
    throw err;
  }
}

/**
 * Generic function to parse API response.
 * It handles both object and array structures.
 * @param {Object} data - The raw API response.
 * @returns {Array} - The array of service orders.
 */
function parseServiceOrders(data) {
  let serviceOrdersArray = [];

  if (data.body) {
    try {
      const parsedBody = JSON.parse(data.body);
      //console.log('Parsed body:', parsedBody); // Debugging log

      if (Array.isArray(parsedBody)) {
        // Response is a direct array
        serviceOrdersArray = parsedBody;
      } else if (parsedBody.serviceOrders && Array.isArray(parsedBody.serviceOrders)) {
        // Response contains a 'serviceOrders' array
        serviceOrdersArray = parsedBody.serviceOrders;
      } else {
        console.warn('Unexpected body structure:', parsedBody);
      }
    } catch (err) {
      console.error('Error parsing JSON body:', err);
    }
  } else if (data.data && Array.isArray(data.data)) {
    // Alternative field 'data' containing the array
    serviceOrdersArray = data.data;
  } else {
    console.warn('No body or data field in API response.');
  }

  //console.log('serviceOrdersArray:', serviceOrdersArray); // Debugging log
  return serviceOrdersArray;
}

/**
 * Mapping function to normalize service order data.
 * It accounts for different field names in API responses.
 * @param {Object} order - The service order object from API response.
 * @returns {Object} - The normalized service order object.
 */
function mapServiceOrder(order) {
  // Format address using the new field structure
  const formatAddress = (order) => {
    if (!order) return 'N/A';
    return `${order.AddressLine1 || ''} ${order.AddressLine2 || ''} ${order.City || ''} ${order.State || ''} ${order.PostalCode || ''}`
      .trim() || 'N/A';
  };

  return {
    serviceOrderNumber: order.serviceOrderNumber || order.ServiceOrderID || 'N/A',
    date: order.date || order.Date || '', // Handle both 'date' and 'Date'
    customerName: order.customerName || order.CustomerName || 'N/A',
    details: order.description || order.details || order.Description || 'N/A', // Handle multiple keys
    customerOrder: order.customerOrder || order.CustomerOrder || '',
    address: formatAddress(order),
    department: order.Department || order.department || 'N/A',
    CustID: order.customerAccount || 'N/A', // Updated key to match AppointmentScreen usage
  };
}