import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import UserContext from './context/UserContext';
import menuItems from '../utils/menuitems';
import './css/MobileFooter.css';

const MobileFooter = ({ effectiveRole }) => {
    const { user } = useContext(UserContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(null);

    if (!user) return null;

    // Use the prop directly, don't calculate again
    const roleId = effectiveRole;
    
    // Filter menu items strictly by role level - removed searchTerm parameter
    const getFilteredItems = (category) => {
        return menuItems.filter(item => 
            item.role <= roleId && 
            item.category === category
        );
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (isMenuOpen) {
            document.body.classList.remove('menu-open');
        } else {
            document.body.classList.add('menu-open');
        }
    };

    const toggleCategory = (category) => {
        setActiveCategory(activeCategory === category ? null : category);
    };

    // Return early if role is undefined
    if (effectiveRole === undefined) {
        return null;
    }

    // Is this an emulated role?
    const isEmulated = roleId !== user.role_id;
    
    // Use same name logic as Home.jsx for consistency
    const userName = user.name || user.display_name || user.displayName || 
                     user.firstName || user.first_name || 
                     user.username || user.email || 'User';

    return (
        <>
            {/* Main Mobile Footer */}
            <footer className="mobile-footer d-md-none">
                <div className="mobile-footer-bar">
                    <Link to="/" className="footer-icon-button">
                        <i className="fas fa-home"></i>
                        <span>Home</span>
                    </Link>
                    
                    {/* Only show if role >= 1 */}
                    {roleId >= 1 && (
                        <Link to="/create-service-order" className="footer-icon-button">
                            <i className="fas fa-clipboard-list"></i>
                            <span>Create Order</span>
                        </Link>
                    )}
                    
                    {/* Only show if role >= 1 */}
                    {roleId >= 1 && (
                        <Link to="/AppointmentWorkFlow" className="footer-icon-button">
                            <i className="fas fa-calendar-check"></i>
                            <span>Appointments</span>
                        </Link>
                    )}
                    
                    <button className="footer-icon-button menu-button" onClick={toggleMenu}>
                        <i className="fas fa-bars"></i>
                        <span>Menu</span>
                    </button>
                </div>
            </footer>

            {/* Mobile Menu Overlay */}
            <div className={`mobile-menu-overlay ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
                    <div className="mobile-menu-header">
                        <div className="user-info">
                            <i className="fas fa-user-circle"></i>
                            <span>{userName}</span>
                            
                            {/* Simple badge - no background elements with gold color */}
                            {isEmulated && (
                                <small style={{
                                    display: 'block',
                                    marginTop: '4px',
                                    fontSize: '0.75rem',
                                    color: '#f0ed9e',
                                    fontWeight: 'bold'
                                }}>
                                    🔄 Viewing as Role {roleId}
                                </small>
                            )}
                        </div>
                        <button className="close-menu" onClick={toggleMenu}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>

                    {/* Search box removed */}

                    <div className="mobile-menu-content">
                        {/* Home Link - Always visible */}
                        <div className="menu-category">
                            <Link to="/" className="category-link home-link" onClick={toggleMenu}>
                                <i className="fas fa-home"></i>
                                <span>Home</span>
                            </Link>
                        </div>

                        {/* Techroom Category - For all roles */}
                        <div className="menu-category">
                            <div 
                                className="category-header" 
                                onClick={() => toggleCategory('Techroom')}
                            >
                                <i className="fas fa-tools"></i>
                                <span>Techroom</span>
                                <i className={`fas fa-chevron-${activeCategory === 'Techroom' ? 'up' : 'down'} toggle-icon`}></i>
                            </div>
                            {activeCategory === 'Techroom' && (
                                <div className="menu-items">
                                    {getFilteredItems('Techroom').map((item, index) => (
                                        <div className="menu-item" key={index}>
                                            {item.isExternal ? (
                                                <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                                                    <i className={item.icon}></i>
                                                    <span>{item.name}</span>
                                                </a>
                                            ) : (
                                                <Link to={item.path} className="menu-link" onClick={toggleMenu}>
                                                    <i className={item.icon}></i>
                                                    <span>{item.name}</span>
                                                </Link>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Operations Category - Only for role >= 2 */}
                        {roleId >= 2 && (
                            <div className="menu-category">
                                <div 
                                    className="category-header" 
                                    onClick={() => toggleCategory('Operations')}
                                >
                                    <i className="fas fa-cogs"></i>
                                    <span>Operations</span>
                                    <i className={`fas fa-chevron-${activeCategory === 'Operations' ? 'up' : 'down'} toggle-icon`}></i>
                                </div>
                                {activeCategory === 'Operations' && (
                                    <div className="menu-items">
                                        {getFilteredItems('Operations').map((item, index) => (
                                            <div className="menu-item" key={index}>
                                                {item.isExternal ? (
                                                    <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                                                        <i className={item.icon}></i>
                                                        <span>{item.name}</span>
                                                    </a>
                                                ) : (
                                                    <Link to={item.path} className="menu-link" onClick={toggleMenu}>
                                                        <i className={item.icon}></i>
                                                        <span>{item.name}</span>
                                                    </Link>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        {/* IT Admin Category - Only for role >= 9 */}
                        {roleId >= 9 && (
                            <div className="menu-category">
                                <div 
                                    className="category-header" 
                                    onClick={() => toggleCategory('IT Admin')}
                                >
                                    <i className="fas fa-user-shield"></i>
                                    <span>IT Admin</span>
                                    <i className={`fas fa-chevron-${activeCategory === 'IT Admin' ? 'up' : 'down'} toggle-icon`}></i>
                                </div>
                                {activeCategory === 'IT Admin' && (
                                    <div className="menu-items">
                                        {getFilteredItems('IT Admin').map((item, index) => (
                                            <div className="menu-item" key={index}>
                                                {item.isExternal ? (
                                                    <a href={item.path} className="menu-link" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>
                                                        <i className={item.icon}></i>
                                                        <span>{item.name}</span>
                                                    </a>
                                                ) : (
                                                    <Link to={item.path} className="menu-link" onClick={toggleMenu}>
                                                        <i className={item.icon}></i>
                                                        <span>{item.name}</span>
                                                    </Link>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileFooter;