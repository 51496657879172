// NavLinks.jsx

import React, { useContext } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import UserContext from './context/UserContext';
import menuItems from '../utils/menuitems';
import { AppRoleEmulationContext } from '../App'; // Import from App instead of Home

// Import components
import Home from './Home';
import CalendarOnCall from './CalendarOnCall';
import CalendarPTO from './CalendarPTO';
import ITAdmin from './ITAdmin';
import UserAdmin from './UserAdmin';
import UFC from './UFC';
import Training from './Training';
import PhoneList from './PhoneList';
import AppointmentWorkFlow from './AppointmentWorkFlow';
import ServiceOrderLookup from './ServiceOrderLookup';
import AppointmentScreen from './AppointmentScreen';
import ManagePOs from './ManagePOs';
import DispatcherBoard from './DispatcherBoard';
import TechnicianTimeOff from './TechnicianTimeOff';
import ITAdminCustomerAccounts from './ITAdminCustomerAccounts';
import CreateServiceOrder from './CreateServiceOrder';
import BillingReview from './BillingReview';
import NewEmployeeSetup from './NewEmployeeSetup';
import DeviceAudit from './DeviceAudit';

export default function NavLinks() {
  const { user } = useContext(UserContext);
  const roleEmulation = useContext(AppRoleEmulationContext);
  
  // Get effective role - handle 0 role correctly
  const roleId = roleEmulation?.effectiveRole !== undefined ? 
                 roleEmulation.effectiveRole : 
                 user?.role_id;
  
  // Debug output
  console.log('NavLinks rendering with roleId:', roleId);

  // Filter menu items by category and role
  const getMenuItemsByCategory = (category) => {
    return menuItems.filter(item => 
      item.role <= roleId && 
      item.category === category
    );
  };

  // Get items for each category
  const techroomItems = getMenuItemsByCategory('Techroom');
  const operationsItems = getMenuItemsByCategory('Operations');
  const itAdminItems = getMenuItemsByCategory('IT Admin');
  
  // Add visual indicator for emulated role - use strict equality
  const isEmulated = roleId !== user?.role_id;

  return (
    <div>
      <div className="content-spacing">
        {/* Main Menu */}
        <div className='container sticky no__padding hidden__on__mobile white__background small__padding__top__bottom grey__bottom__border' >
          <div className='row'>
            <div className='col'>
              <Button className='btn-collins border fill__width' as={Link} to="/">Home</Button>
              
              {/* Add emulation indicator badge */}
              {isEmulated && (
                <small style={{
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '3px',
                  right: '15px',
                  color: '#f0ed9e',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  padding: '1px 4px',
                  borderRadius: '3px'
                }}>
                  Role {roleId}
                </small>
              )}
            </div>
            
            <div className="col">
              <Dropdown>
                <Dropdown.Toggle className='btn-collins border fill__width'>
                  Techroom
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {techroomItems.map((item, index) => (
                    item.isExternal ? (
                      <Dropdown.Item href={item.path} target="_blank" key={index}>
                        <i className={`${item.icon} me-2`}></i> {item.name}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item as={Link} to={item.path} key={index}>
                        <i className={`${item.icon} me-2`}></i> {item.name}
                      </Dropdown.Item>
                    )
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {roleId >= 2 && (
              <div className='col'>
                <Dropdown>
                  <Dropdown.Toggle className='btn-collins border fill__width'>
                    Operations
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {operationsItems.map((item, index) => (
                      item.isExternal ? (
                        <Dropdown.Item href={item.path} target="_blank" key={index}>
                          <i className={`${item.icon} me-2`}></i> {item.name}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item as={Link} to={item.path} key={index}>
                          <i className={`${item.icon} me-2`}></i> {item.name}
                        </Dropdown.Item>
                      )
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {roleId >= 9 && (
              <div className='col'>
                <Dropdown>
                  <Dropdown.Toggle className='btn-collins border fill__width'>
                    IT Admin
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {itAdminItems.map((item, index) => (
                      item.isExternal ? (
                        <Dropdown.Item href={item.path} target="_blank" key={index}>
                          <i className={`${item.icon} me-2`}></i> {item.name}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item as={Link} to={item.path} key={index}>
                          <i className={`${item.icon} me-2`}></i> {item.name}
                        </Dropdown.Item>
                      )
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        {/* Routes */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/CalendarOnCall' element={<CalendarOnCall />} />
          <Route path='/CalendarPTO' element={<CalendarPTO />} />
          <Route path='/PhoneList' element={<PhoneList />} />
          <Route path='/Training' element={<Training />} />
          
          {(roleId >= 1) && (
            <>
              <Route path='/AppointmentWorkFlow' element={<AppointmentWorkFlow />} />
              <Route path='/create-service-order' element={<CreateServiceOrder />} />
            </>
          )}
          
          <Route
            path='/service-orders/:serviceOrderNumber/appointments'
            element={<AppointmentScreen />}
          />
          
          {roleId >= 2 && (
            <>
              <Route path='/ServiceOrderLookup' element={<ServiceOrderLookup />} />
              <Route path='/ManagePOs' element={<ManagePOs />} />
              <Route path='/DispatcherBoard' element={<DispatcherBoard />} />
              <Route path='/TechnicianTimeOff' element={<TechnicianTimeOff />} />
              <Route path='/NewEmployeeSetup' element={<NewEmployeeSetup />} />
            </>
          )}
          
          {roleId >= 9 && (
            <>
              <Route path='/UserAdmin' element={<UserAdmin />} />
              <Route path='/UFC' element={<UFC />} />
              <Route path='/customeraccounts' element={<ITAdminCustomerAccounts />} />
              <Route path='/billingreview' element={<BillingReview />} />
              <Route path='/deviceaudit' element={<DeviceAudit />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}
