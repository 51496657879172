import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

export default function CalendarOnCall() {
    const [events, setEvents] = useState(null);
    const [employees, setEmployees] = useState(null);

    const [selectedEmployee, setSelectedEmployee] = useState("all");
    const [displayAZ, setDisplayAZ] = useState(true);
    const [displayCO, setDisplayCO] = useState(true);
    const [displayHVAC, setDisplayHVAC] = useState(true);
    const [displayNV, setDisplayNV] = useState(true);
    const [displayOM, setDisplayOM] = useState(true);
    const [displayTX, setDisplayTX] = useState(true);
    const [displayedMonth, setDisplayedMonth] = useState(null);

    const selectEmployee = (e) => setSelectedEmployee(e.target.value);
    const toggleAZ = () => setDisplayAZ(!displayAZ);
    const toggleCO = () => setDisplayCO(!displayCO);
    const toggleHVAC = () => setDisplayHVAC(!displayHVAC);
    const toggleNV = () => setDisplayNV(!displayNV);
    const toggleOM = () => setDisplayOM(!displayOM);
    const toggleTX = () => setDisplayTX(!displayTX);

    const employeeUrl = 'https://sheets.googleapis.com/v4/spreadsheets/1GpqlcdfNXczFA27G2lOSfc8jQkMKIrDf579_TZmPmwQ/values/EMPLOYEES!a2:c?key=AIzaSyAvZN9nvNLLvi0H41xHZxxXKvFwAD9YyVQ';
    const calendarUrl = 'https://sheets.googleapis.com/v4/spreadsheets/1GpqlcdfNXczFA27G2lOSfc8jQkMKIrDf579_TZmPmwQ/values/CALENDAR_DATA!a2:n?key=AIzaSyAvZN9nvNLLvi0H41xHZxxXKvFwAD9YyVQ';

    useEffect(() => {
        if(events === null) {
            axios.get(calendarUrl).then((response) => {
                if (response.ok) alert("Error retrieving Calendar data!");
                else {
                    let defaultTextColor = "#f5f5f5";
                    let defaultBorderColor = "#282828";
                    let alertTextColor = "#ff7d7d";
                    let alertBorderColor = "#ff0000";
        
                    let omEventColor = "#1b3752";
                    let azEventColor = "#825901";
                    let azhEventColor = "#826d01";
                    let coEventColor = "#016682";
                    let nvEventColor = "#823501";
                    let txEventColor = "#518201";

                    var eArray = [];

                    response.data.values.forEach((item) => {
                        let theDate = item[0];

                        //OM
                        let om1Name = item[1];
                        let om2Name = item[2];
                        let omTextColor = defaultTextColor;
                        let omBorderColor = defaultBorderColor;
                        if (om1Name === "N/A" || om2Name === "N/A") {
                            //omTextColor = alertTextColor;
                            omBorderColor = alertBorderColor;
                        }
                        let omEvent = {
                            start: theDate,
                            end: theDate,
                            title: "OM1 "+om1Name+" | OM2 "+om2Name,
                            groupId: "OM",
                            allDay: true,
                            backgroundColor: omEventColor,
                            textColor: omTextColor,
                            borderColor: omBorderColor,
                            employee1: om1Name,
                            employee2: om2Name
                        };
                        if (om1Name !== "N/A" || om2Name !== "N/A") eArray.push( omEvent );

                        // AZ
                        let az1Name = item[3];
                        let az2Name = item[4];
                        let azTextColor = defaultTextColor;
                        let azBorderColor = defaultBorderColor;
                        if (az1Name === "N/A" || az2Name === "N/A") {
                            //azTextColor = alertTextColor;
                            azBorderColor = alertBorderColor;
                        }

                        let azEvent = {
                            start: theDate,
                            end: theDate,
                            title: "AZ1 "+az1Name+" | AZ2 "+az2Name,
                            groupId: "AZ",
                            allDay: true,
                            backgroundColor: azEventColor,
                            textColor: azTextColor,
                            borderColor: azBorderColor,
                            employee1: az1Name,
                            employee2: az2Name
                        };
                        if (az1Name !== "N/A" || az2Name !== "N/A") eArray.push( azEvent );

                        // AZH
                        let azh1Name = item[5];
                        let azh2Name = item[6];
                        let azh3Name = item[7];
                        let azhTextColor = defaultTextColor;
                        let azhBorderColor = defaultBorderColor;
                        if (azh1Name === "N/A" || azh2Name === "N/A" || azh3Name === "N/A") {
                            //azhTextColor = alertTextColor;
                            azhBorderColor = alertBorderColor;
                        }

                        let azhEvent = {
                            start: theDate,
                            end: theDate,
                            title: "HVAC1 "+azh1Name+" | HVAC2 "+azh2Name+" | HVAC3 "+azh3Name,
                            groupId: "AZH",
                            allDay: true,
                            backgroundColor: azhEventColor,
                            textColor: azhTextColor,
                            borderColor: azhBorderColor,
                            employee1: azh1Name,
                            employee2: azh2Name
                        };
                        if (azh1Name !== "N/A" || azh2Name !== "N/A" || azh3Name !== "N/A") eArray.push( azhEvent );

                        // NV
                        let nv1Name = item[8];
                        let nv2Name = item[9];
                        let nvTextColor = defaultTextColor;
                        let nvBorderColor = defaultBorderColor;
                        if (nv1Name === "N/A" || nv2Name === "N/A") {
                            //nvTextColor = alertTextColor;
                            nvBorderColor = alertBorderColor;
                        }

                        let nvEvent = {
                            start: theDate,
                            end: theDate,
                            title: "NV1 "+nv1Name+" | NV2 "+nv2Name,
                            groupId: "NV",
                            allDay: true,
                            backgroundColor: nvEventColor,
                            textColor: nvTextColor,
                            borderColor: nvBorderColor,
                            employee1: nv1Name,
                            employee2: nv2Name
                        };
                        if (nv1Name !== "N/A" || nv2Name !== "N/A") eArray.push( nvEvent );

                        // TX
                        let tx1Name = item[10];
                        let tx2Name = item[11];
                        let txTextColor = defaultTextColor;
                        let txBorderColor = defaultBorderColor;
                        if (tx1Name === "N/A" || tx2Name === "N/A") {
                            //txTextColor = alertTextColor;
                            txBorderColor = alertBorderColor;
                        }

                        let txEvent = {
                            start: theDate,
                            end: theDate,
                            title: "TX1 "+tx1Name+" | TX2 "+tx2Name,
                            groupId: "TX",
                            allDay: true,
                            backgroundColor: txEventColor,
                            textColor: txTextColor,
                            borderColor: txBorderColor,
                            employee1: tx1Name,
                            employee2: tx2Name
                        };
                        if (tx1Name !== "N/A" || tx2Name !== "N/A") eArray.push( txEvent );

                        // CO
                        
                        let co1Name = item[12];
                        let co2Name = item[13];
                        let coTextColor = defaultTextColor;
                        let coBorderColor = defaultBorderColor;
                        if (co1Name === "N/A" || co2Name === "N/A") {
                            //coTextColor = alertTextColor;
                            coBorderColor = alertBorderColor;
                        }

                        let coEvent = {
                            start: theDate,
                            end: theDate,
                            title: "CO1 "+co1Name+" | CO2 "+co2Name,
                            groupId: "CO",
                            allDay: true,
                            backgroundColor: coEventColor,
                            textColor: coTextColor,
                            borderColor: coBorderColor,
                            employee1: co1Name,
                            employee2: co2Name
                        };
                        if (co1Name !== "N/A" || co2Name !== "N/A") eArray.push( coEvent );
                    });
                    setEvents(eArray);
                }
            })
        }
    },[]);

    useEffect(() => {
        if(employees === null) {
            axios.get(employeeUrl).then((response) => {
                //console.log(response)
                let eArray = [];
                response.data.values.forEach((item) => {
                    if (item.length > 0) {
                        eArray.push(item[0]);
                    }
                })
                setEmployees(eArray.sort());
            })
        }
    })

    const filterEvents = () => {
        var displayedEvents = document.getElementsByClassName("fc-daygrid-event-harness");
        for (var i=0; i<displayedEvents.length; i++) {
            if((!displayAZ && displayedEvents[i].innerHTML.includes("AZ1")) ||
                (!displayHVAC && displayedEvents[i].innerHTML.includes("HVAC1")) ||
                (!displayCO && displayedEvents[i].innerHTML.includes("CO1")) ||
                (!displayNV && displayedEvents[i].innerHTML.includes("NV1")) ||
                (!displayOM && displayedEvents[i].innerHTML.includes("OM1")) ||
                (!displayTX && displayedEvents[i].innerHTML.includes("TX1")) ||
                (selectedEmployee != 'all' && !displayedEvents[i].innerHTML.includes(selectedEmployee))) {
                displayedEvents[i].style.display = "none";
            } else {
                displayedEvents[i].style.display = "initial";
            }
        }
        //console.log(document.getElementsByClassName("fc-daygrid-event-harness"));
    }
    useEffect (() => filterEvents(),[ selectedEmployee, displayAZ, displayCO, displayHVAC, displayNV, displayOM, displayTX, displayedMonth ])

    const handleDatesSet = (info) => {
        setDisplayedMonth(info.view.currentStart.toISOString());
    }

    return (
        <div>
            <h1>On Call Calendar</h1>
            <div className="container no__padding">
                <div className="row">
                <div className="col-md-12">
                    <div id="filters">
                    <form className="form-inline">                   
                        
                        <div class="select-wrapper">
                            <select className="form-control filter fill__width" id="employeeSelect" name="marketSelect" onChange={selectEmployee}>
                                <option value='all'>All Employees</option>
                                {
                                    employees !== null &&
                                    employees.map((employee, index) => (<option value={employee} key={index}>{employee}</option>))
                                }
                            </select>
                        </div>
                        <br />
                        <br />
                        <div className="checkbox-container" id="checkbox-div">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="azCheckbox" name="azCheckbox" value="azCheckbox" autoComplete="off" onChange={toggleAZ} checked={displayAZ} />
                                <label className="form-check-label" htmlFor="azCheckbox">AZ</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="coCheckbox" name="coCheckbox" value="coCheckbox" autoComplete="off" onChange={toggleCO} checked={displayCO} />
                                <label className="form-check-label" htmlFor="coCheckbox">CO</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="azhCheckbox" name="azhCheckbox" value="azhCheckbox" autoComplete="off" onChange={toggleHVAC} checked={displayHVAC} />
                                <label className="form-check-label" htmlFor="azhCheckbox">HVAC</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="nvCheckbox" name="nvCheckbox" value="nvCheckbox" autoComplete="off" onChange={toggleNV} checked={displayNV} />
                                <label className="form-check-label" htmlFor="nvCheckbox">NV</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="omCheckbox" name="omCheckbox" value="omCheckbox" autoComplete="off" onChange={toggleOM} checked={displayOM} />
                                <label className="form-check-label" htmlFor="omCheckbox">OM</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="txCheckbox" name="txCheckbox" value="txCheckbox" autoComplete="off" onChange={toggleTX} checked={displayTX} />
                                <label className="form-check-label" htmlFor="txCheckbox">TX</label>
                            </div>
                        </div>
                    </form>
                    </div>
                    <br />
                </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <FullCalendar 
                            plugins={[dayGridPlugin]} 
                            events={events} 
                            contentHeight="auto"
                            datesSet={handleDatesSet}
                        />
                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}